

let fakeGhosts = [];

for (let i = 0; i <= 14; i++) {
  fakeGhosts.push({
    "image": {
      "png": require(`../Assets/Ghosts/lineup/temp/SG${i}.png`).default,
      "webp": require(`../Assets/Ghosts/lineup/temp/SG${i}.webp`).default,
      "width": 383,
      "height": 291
    },
  });
}
export const ghosts = [
  {
      "name": "Bananice",
      "image": {
          "png": require("../Assets/Ghosts/lineup/101_bananice.png").default,
          "webp": require("../Assets/Ghosts/lineup/101_bananice.webp").default,
          "width": 383,
          "height": 291
      },
      "card": require("../Assets/Ghosts/Cards/FRACTAL Bananice.jpg").default,
      "infos": {
          "kawaii name": "Freezofear",
          "kaiju name": "Bananice",
          "level": "6",
          "family": "Fractal",
          "types": "Ice / Merger",
          "nest": "An old abandoned fridge",
          "receptacle": "Banana flavored ice-creams",
          "age": "Horror",
          "rarity": "Uncommon",
          "deplacement": "Jumping, sliding and climbing.",
          "Weakness": "To defeat Bananice, you\u2019ll need to expose it to a strong enough heat source\u2026 Such as the light of the sun, reflected through a giant magnifying glass!",
          "": "",
          "attacks": [
              {
                  "number": 1,
                  "name": "ICE-SCREAM",
                  "text": "In Kawa\u00ef form, FREEZOFEAR can freeze people over just by going trough them. Creepy\u2026 And cold! Luckily, a strong enough heat source is enough to revert the ghost\u2019s effect and brings you back to room temperature. In its booster mode, BANANICE also becomes able to freeze everything by howling out an icy blast of air, that freezes entire areas!"
              },
              {
                  "number": 2,
                  "name": "BANANA-SCOOPS",
                  "text": "In his booster mode, Bananice can shoots ice-cream scoops projectile from its hand. While those can\u2019t turn you into ice, they\u2019re still fairly cold\u2026 And delicious !"
              },
              {
                  "number": 3,
                  "name": "BANANICE-AGE",
                  "text": "In Booster mode, once capped on boo energy, BANANICE can shoots an icy beam from its head that will transform the sky into a potent ice-storm, quickly lowering the temperature and sending its surrounding into a new ice-age !"
              }
          ],
          "defenses": [
              {
                  "number": 1,
                  "name": "ICE-CAPADE",
                  "text": "In Booster mode, BANANICE leaves a sheet of ice under its feets, wherever it goes. This allows it to increase its speed and to dodge incoming attack\u2026 By sliding on ice!"
              },
              {
                  "number": 2,
                  "name": "BOO ENERG-ICED",
                  "text": "Where most Ghosts have to feed on fear to power up their attacks, FREEZOFEAR/BANANICE is able to convert cryogenized protein into Boo energy. This allows it to power up\u2026 Just by eating ice-creams!"
              }
          ]
      }
  },
  {
      "name": "Glougloux",
      "image": {
          "png": require("../Assets/Ghosts/lineup/102_glougloux.png").default,
          "webp": require("../Assets/Ghosts/lineup/102_glougloux.webp").default,
          "width": 383,
          "height": 291
      },
      "card": require("../Assets/Ghosts/Cards/MOLECULAR Glougloux.jpg").default,
      "infos": {
          "kawaii name": "Freezofear",
          "kaiju name": "Glougloux",
          "level": "8",
          "family": "Molecular",
          "types": "Multiplier / Teleporter / Shrieker",
          "nest": "Stored in Boocap",
          "receptacle": "None",
          "age": "Terror",
          "rarity": "Rare",
          "deplacement": "Teleportation (in a spiral pattern)",
          "Weakness": "Despite his high level, GLOUGLOUX is a very frail Ghost, that could be easily boocaped even in its booster mode\u2026 Provided you can actually reach it as it keeps teleporting away and drowning you under a sea of cloned eggs! To catch it, you\u2019ll need to discover the spiral of its teleportation\u2019s pattern, in order to guess where it\u2019ll appear next.",
          "": "",
          "attacks": [
              {
                  "number": 1,
                  "name": "MULTEGGPLICATION",
                  "text": "In booster mode, GLOUGLOUX can multiply its eggs exponentially by cloning them. One egg become two eggs, become four eggs, become eight eggs\u2026 Become a crushing wave of thousands of ghastly eggs!"
              },
              {
                  "number": 2,
                  "name": "STUNNING SCREAM",
                  "text": "In booster mode, GLOUGLOUX can let out a screeching scream that\u2019ll temporally stun anyone hearing it."
              },
              {
                  "number": 3,
                  "name": "SPECTRAL SCREAM",
                  "text": "When capped on boo energy, GLOUGLOUX can let out an even more terrifying scream that\u2019ll not only temporally stuns everyone around\u2026 But also hatches all of its eggs into cloned version of GLOUGLOUX!"
              },
              {
                  "number": 4,
                  "name": "EGG SWARM",
                  "text": "In booster mode, GLOUGLOUX can order its swarm of eggs and clones around to use them as its personal army, quickly overwhelming its foe under their numbers."
              }
          ],
          "defenses": [
              {
                  "number": 1,
                  "name": "TELEGGPORTATION",
                  "text": "When in danger, GLOUGLOUX will teleport away in a spiral pattern, leaving an egg behind it. The eggs stay dormant until GLOUGLOUX hatches them with his SPECTRAL SCREAM."
              },
              {
                  "number": 2,
                  "name": "TAKE SHELL-TER",
                  "text": "When scared GLOUGLOUX can retreat inside its own egg shell\u2026 Though this doesn\u2019t really provide it with any actual protection at all!"
              }
          ]
      }
  },
  {
      "name": "Zip-zap",
      "image": {
          "png": require("../Assets/Ghosts/lineup/103_zip-zap.png").default,
          "webp": require("../Assets/Ghosts/lineup/103_zip-zap.webp").default,
          "width": 383,
          "height": 291
      },
      "card": require("../Assets/Ghosts/Cards/SPECTRAL ZIP-ZAP.jpg").default,
      "infos": {
          "kawaii name": "Freezofear",
          "kaiju name": "Zip Zap",
          "level": "4",
          "family": "Spectral",
          "types": "Electric / Hypnotic",
          "nest": "An old, broken TV screen",
          "receptacle": "None",
          "age": "Dread",
          "rarity": "Uncommon",
          "deplacement": "Floating and swimming in the air like an eel",
          "Weakness": "To defeat ZIP-ZAP, you\u2019ll need to avoid looking at it in the eye or at any screen it may have haunted, as doing so will get you hypnotized! Best way to achieve that? Fight it blinded!",
          "": "",
          "attacks": [
              {
                  "number": 1,
                  "name": "HYPNO FLASH",
                  "text": "In its Kawa\u00ef mode ZIP-ZAP can hypnotize anyone looking at a screen he\u2019s haunting! And in booster mode, things gets worse as its eyes themselves turn into screens\u2026 Making it able to hypnotize anyone with just a look!"
              },
              {
                  "number": 2,
                  "name": "ZAPING BOLT",
                  "text": "In its Booster mode, ZIP-ZAP can shoots electric bolt from its head fin, like an electric eel. Beware its projectile, if you don\u2019t\u2019 wanna get fry!"
              }
          ],
          "defenses": [
              {
                  "number": 1,
                  "name": "ZAPING-IN / ZAPING-OUT",
                  "text": "In Kawa\u00ef form, ZIP-ZAP can jump in and out of any screens he gets in touch with in order to haunt them\u2026 Becoming impossible to attack while it\u2019s inside one!"
              }
          ]
      }
  },
  {
      "name": "Trashotic",
      "image": {
          "png": require("../Assets/Ghosts/lineup/104_trashotic.png").default,
          "webp": require("../Assets/Ghosts/lineup/104_trashotic.webp").default,
          "width": 383,
          "height": 291
      },
      "card": require("../Assets/Ghosts/Cards/VISCOUS Trashotic.jpg").default,
      "infos": {
          "kawaii name": "Spong",
          "kaiju name": "Trashotic",
          "level": "4",
          "family": "Viscous",
          "types": "Toxic / Merger",
          "nest": "A dirty trashcan",
          "receptacle": "A dirtier garbage can",
          "age": "Dread",
          "rarity": "Uncommon",
          "deplacement": "Levitating in Kawa\u00ef form. Walking in booster mode.",
          "Weakness": "TRASHOTIC loves the smell of garbage and hates every other smells. Thus the best way to defeat it is to cover its own odor with an ever more powerful one, such as a super strong rose-scented perfume!",
          "": "",
          "attacks": [
              {
                  "number": 1,
                  "name": "SMELLY BREATH",
                  "text": "In both their Kawa\u00ef and Boosted mode, SPONG and TRASHOTIC can use their awful smelling breath as a weapon that is sure to knock-out your sense of smell. Beware : the toxic fumes from their breaths can linger long after they\u2019re gone!"
              },
              {
                  "number": 2,
                  "name": "GARBAGE BALL",
                  "text": "In its Booster mode, TRASHOTIC can shoot dirty balls of garbage goo from its hands, that are not only super-smelly\u2026 But also super-sticky!"
              }
          ],
          "defenses": [
              {
                  "number": 1,
                  "name": "SMELLY AURA",
                  "text": "Ever smelled a bag of garbage filled we dirty baby diapers? SPONG/TRASHOTIC smells like a billion of them, compacted together into one single ghastly pack of trash. It\u2019s not foul smelling\u2026 It\u2019s worse! And it creates a smelly aura around it that is sure to disturb anyone coming close to it."
              }
          ]
      }
  },
  {
      "name": "Mikroo",
      "image": {
          "png": require("../Assets/Ghosts/lineup/106_mikroo.png").default,
          "webp": require("../Assets/Ghosts/lineup/106_mikroo.webp").default,
          "width": 383,
          "height": 291
      },
      "card": require("../Assets/Ghosts/Cards/MOLECULAR MIKROO.jpg").default,
      "infos": {
          "kawaii name": "Spong",
          "kaiju name": "Mikroo",
          "level": "2",
          "family": "Molecular",
          "types": "Shrinker",
          "nest": "A dusty antique",
          "receptacle": "None",
          "age": "Fright",
          "rarity": "Uncommon",
          "deplacement": "Levitation",
          "Weakness": "MIKROO is so fearful that defeating it only requires being able to scare it off\u2026 Maybe by making yourself appear MUCH bigger than it is?",
          "": "",
          "attacks": [
              {
                  "number": 1,
                  "name": "TINY TOUCH",
                  "text": "The cowardly ghost MIKROO can miniaturize anyone it touches or goes through, both in its Kawa\u00ef and Booster mode. Take care not to get in contact with it... Or you'll get to experience the world through the eyes of a mouse-sized human! Being a cowardly ghost who\u2019s afraid of everything bigger than it is, MIKROO is also terrified of other ghosts. And so, its TINY TOUCH has the power to drain boo energy, in order to stop its enemy from using their powers against it."
              }
          ],
          "defenses": [
              {
                  "number": 1,
                  "name": "DUST CLOUD",
                  "text": "To evade its enemy (and everything looking bigger than it) MIKROO can spit out a massive dust cloud that will gets everyone blinded and coughing\u2026 Giving it time to run away!"
              }
          ]
      }
  },
  {
      "name": "Raijin",
      "image": {
          "png": require("../Assets/Ghosts/lineup/107_raijin.png").default,
          "webp": require("../Assets/Ghosts/lineup/107_raijin.webp").default,
          "width": 383,
          "height": 291
      },
      "card": require("../Assets/Ghosts/Cards/SPECTRAL RAIJIN.jpg").default,
      "infos": {
          "kawaii name": "Spong",
          "kaiju name": "Raijin",
          "level": "6",
          "family": "Spectral",
          "types": "Electric / Cloud",
          "nest": "An old electric generator",
          "receptacle": "None",
          "age": "Horror",
          "rarity": "Very rare",
          "deplacement": "Levitation. Though its rather slow as a cloud, it can move ten times faster once it\u2019s charged with electricity!",
          "Weakness": "As dangerous as it is once charged with electricity, RA\u00cfJIN is unable to use its attack without it\u2026 Making draining it out of his juice a winning strategy!",
          "": "",
          "attacks": [
              {
                  "number": 1,
                  "name": "SPOOK-CIRCUIT",
                  "text": "In its Kawa\u00ef form, RA\u00cfJIN can overload and short-circuit any electric appliance, just by going through it. Causing chaos and mayhem as tech get disabled in a fountain of sparks!"
              },
              {
                  "number": 2,
                  "name": "ELEKSHOCK",
                  "text": "In its Booster mode, RA\u00cfJIN can shoot thunderbolt from its antennas to zap its foes with. Upon impact, the target feels a shock go down their entire body, numbing them\u2026 And will see a portion of their boo energy drained!"
              },
              {
                  "number": 3,
                  "name": "RAIJIN STORM (unused in episode)",
                  "text": "In booster mode, once capped on Boo energy, RA\u00cfJIN can unleash this powerful attack. Allowing it to either:  \nA - Pump the energy back into the grid to use SPOOK-CIRCUIT on every appliance (lamps, hairdryers, TVs, ovens, etc.) connected to it.\nB \u2013 Shoot a super powerful thunderbolt that break into smaller bolts to reach several targets at once or converge on a single one. The electric shock is EXTREMLY powerful!"
              }
          ],
          "defenses": [
              {
                  "number": 1,
                  "name": "RAIJIN CLOUD (unused in episode)",
                  "text": "In booster mode, RA\u00cfJIN can surrounds itself with a cloud to avoid being seen and to protect itself. The ghost can thus disappear by melting into the sky without attracting attention. Even if something (a helicopter or a plane for instance) would enter the cloud zone, they\u2019d come out several seconds later, none the wiser."
              },
              {
                  "number": 2,
                  "name": "CHARGING UP",
                  "text": "Where most Ghosts have to feed on fear to power up their attacks, RA\u00cfJIN is also able to convert electricity into Boo energy. This allows it to power itself up, by connecting its body to a powerful electric current."
              }
          ]
      }
  },
  {
      "name": "Pharaok",
      "image": {
          "png": require("../Assets/Ghosts/lineup/108_pharaok.png").default,
          "webp": require("../Assets/Ghosts/lineup/108_pharaok.webp").default,
          "width": 383,
          "height": 291
      },
      "card": require("../Assets/Ghosts/Cards/STRANGE PHARAOK.jpg").default,
      "infos": {
          "kawaii name": "Spong",
          "kaiju name": "Pharaok",
          "level": "7",
          "family": "Strange",
          "types": "Summoner / Sun / Rock",
          "nest": "Pharaoh\u2019s Mask",
          "receptacle": "None",
          "age": "Panic",
          "rarity": "Unique",
          "deplacement": "Levitation",
          "Weakness": "Since PHARAOK\u2019s powers come from its Mummy Army ability, they must first be turned back to their human self in order to deprive the Pharaoh of its powers!",
          "": "",
          "attacks": [
              {
                  "number": 1,
                  "name": "MUMIFICATION RAY",
                  "text": "In Kawa\u00ef form, PHARAOK can shoots laser from its crown, turning anyone it hits into a shambling mummy! As it gets into booster mode, it\u2019ll grow two arms that can shoot Mumification Rays as well\u2026 Making it a triple threat!"
              },
              {
                  "number": 2,
                  "name": "DESERT HEAT AURA",
                  "text": "In both Kawa\u00ef and Booster mode, PHARAOK passively exudes a paranormal heat so strong it can turn entire cities into deserts with enough time! The temperature emanating from its aura is so strong, it can even overwhelms other Ghosts, draining them of their Boo energy."
              },
              {
                  "number": 3,
                  "name": "THE GREAT PYRAMID",
                  "text": "Once capped on Boo energy, PHARAOK can use its ultimate attack to convoke a Ghastly Pyramid. Anyone caught inside of it will experiences tremendous heat, as temperature quickly rises up, accelerating the process of turning the place into a desert from a couple of days\u2026 To mere minutes!"
              }
          ],
          "defenses": [
              {
                  "number": 1,
                  "name": "MUMMY ARMY",
                  "text": "In booster mode, the mummies created by PHARAOK provide him with Boo energy, making him extremely resilient while they\u2019re around! Fortunately, hitting the mummies with any sort of Boo attack transforms them back to their old human self, negating PHARAOK\u2019s advantage."
              },
              {
                  "number": 2,
                  "name": "UNKNOWN",
                  "text": "(Not used in episode)"
              }
          ]
      }
  },
  {
      "name": "Mizuo",
      "image": {
          "png": require("../Assets/Ghosts/lineup/109_mizuo.png").default,
          "webp": require("../Assets/Ghosts/lineup/109_mizuo.webp").default,
          "width": 383,
          "height": 291
      },
      "card": require("../Assets/Ghosts/Cards/FLEXIBLE MIZUO.jpg").default,
      "infos": {
          "kawaii name": "Droplet",
          "kaiju name": "Mizuo",
          "level": "7",
          "family": "Flexible",
          "types": "Water / Multiplier / Merger",
          "nest": "An old water flask",
          "receptacle": "Watertanks",
          "age": "Panic",
          "rarity": "Uncommon",
          "deplacement": "Levitating in Kawa\u00ef mode. Walking and jumping on the water tank\u2019s four legs in Booster mode.",
          "Weakness": "As a Multiplier Ghost, Mizuo relies on the size of its pack to defeat its foes. But the fact that the whole pack disappears when the Alpha goes down means it isn\u2019t necessary to defeat them all\u2026 Only to clog the plumbing of the original one!",
          "": "",
          "attacks": [
              {
                  "number": 1,
                  "name": "UNKNOWN (unused in episode)",
                  "text": "DROPLET\u2019s Kawa\u00ef mode power is still unknown to this day."
              },
              {
                  "number": 2,
                  "name": "AQUA BLAST",
                  "text": "In its Booster mode, MIZUO can use its arm like a water canon, shooting powerful blast of water that\u2019ll knock anyone away! And while the rate of fire of a single isolated MIZUO may be too slow to reliably hit a moving target\u2026 The unending barrage coming from a full pack is sure to eventually connect at some point!"
              },
              {
                  "number": 3,
                  "name": "ALPHA ROAR",
                  "text": "In its Booster mode, MIZUO can let out a powerful roar that calls all the members of its pack to come back to the Alpha\u2019s position. This roar is so strong, that they can hear it no matter where they are."
              }
          ],
          "defenses": [
              {
                  "number": 1,
                  "name": "AQUA JET (unused in episode)",
                  "text": "While MIZUO is unable to levitate in its Booster mode; it can use its arms as a jetpack by shooting powerful streams of water downward. The only disadvantage of this defensive power is that it locks MIZUO\u2019s water canon while using it, preventing it to use its AQUA BLAST in the meantime."
              },
              {
                  "number": 2,
                  "name": "TENTACULAR PLUMBING",
                  "text": "In its booster mode, MIZUO is capable of haunting any water tubes, water tanks and other plumbing utilities connected to the same water circuit as it is\u2026 Effectively allowing the Alpha specimen to multiply itself to form a redoubtable pack of identical ghosts!"
              }
          ]
      }
  },
  {
      "name": "Maastar",
      "image": {
          "png": require("../Assets/Ghosts/lineup/112_maastar.png").default,
          "webp": require("../Assets/Ghosts/lineup/112_maastar.webp").default,
          "width": 383,
          "height": 291
      },
      "card": require("../Assets/Ghosts/Cards/SPECTRAL MAASTAR.jpg").default,
      "infos": {
          "kawaii name": "Droplet",
          "kaiju name": "Maastar",
          "level": "7",
          "family": "Spectral",
          "types": "Metal / Musical / Hypnotic",
          "nest": "A ukulele",
          "receptacle": "None",
          "age": "Panic",
          "rarity": "Rare",
          "deplacement": "Levitating\u2026 In music!",
          "Weakness": "As Mastaar feeds upon the adoration of its mesmerized audience, you\u2019ll need to break the hypnotic spell it keeps them under\u2026 By drowning its music with your own, in a true solo battle. Rock on!",
          "": "",
          "attacks": [
              {
                  "number": 1,
                  "name": "SING-A-BOO",
                  "text": "In Kawa\u00ef form, MASTAAR\u2019s singing can release shot-ranged mesmerizing waves that will hypnotize anyone listening to it, turning its victims into its absolute fanboys and fangirls of MASTAAR!"
              },
              {
                  "number": 2,
                  "name": "BOOTALIKA",
                  "text": "As it gets into booster mode, MASTAAR can then expand its repertoire to music as well, as he becomes able to mesmerized entire crowds at long-range, just by performing for them. Such a rockstar!"
              },
              {
                  "number": 3,
                  "name": "SPOOKLIGHTS",
                  "text": "In booster mode, once capped on Boo energy, MASTAAR can use the spotlights on its back to shoot immobilizing rays, that allows him to turn anyone trying to overshadows it into a statue! So covering your ears won\u2019t be nearly enough to defeat it!"
              }
          ],
          "defenses": [
              {
                  "number": 1,
                  "name": "MUSICAL INVOCATION",
                  "text": "In booster mode, MASTAAR can convoke musical instrument made out pure concentrated Boo energy. Those then act as amplifiers for its other powers, increasing their range and boosting their effects (as well as Mastaar\u2019s general loudness!)."
              },
              {
                  "number": 2,
                  "name": "BOO STAR",
                  "text": "Where most Ghosts have to feed on fear to power up their attacks, MASTAAR is also able to feed on the adoration of its mesmerized \u201cfans\u201d.  The more they cheers for it\u2026 The more Boo energy it gets!"
              }
          ]
      }
  },
  {
      "name": "Krikkrok",
      "image": {
          "png": require("../Assets/Ghosts/lineup/114_krikkrok.png").default,
          "webp": require("../Assets/Ghosts/lineup/114_krikkrok.webp").default,
          "width": 383,
          "height": 291
      },
      "card": require("../Assets/Ghosts/Cards/SPECTRAL KRIK KROK.jpg").default,
      "infos": {
          "kawaii name": "Gmagia",
          "kaiju name": "Krik Krok",
          "level": "4",
          "family": "Spectral",
          "types": "Plant / Sun",
          "nest": "A plant pot",
          "receptacle": "None",
          "age": "Dread",
          "rarity": "Uncommon",
          "deplacement": "Levitation",
          "Weakness": "KRIK KROK\u2019s powers all originates from the seed on its head. As such, in order to defeat it, you\u2019ll only need to reach it with your attacks\u2026  While preventing KRIK KROK from shielding it with its leaves!",
          "": "",
          "attacks": [
              {
                  "number": 1,
                  "name": "SPECTRAL BRAMBLES",
                  "text": "In both its Kawa\u00ef and Booster mode, KRIK KROK can shoot pollen-like projectiles from its seed, that very quickly sprout into giant brambles as soon as they hit something solid ! Those brambles are so invasive that they\u2019ll not only entrap people\u2026 They\u2019ll quickly turn whatever area KRIK KROK is attacking into a real thorny jungle!"
              },
              {
                  "number": 2,
                  "name": "SPINNING LEAVES",
                  "text": "In booster mode, KRIK KROK can quickly spin on itself, to use the leaves on its head like blades from a dangerous lawnmower !"
              }
          ],
          "defenses": [
              {
                  "number": 1,
                  "name": "LEAFY SHIELD",
                  "text": "In booster mode, KRIK KROK can retract the leaves on its head to shield its seed, rendering it impervious to any attack. Fortunately, doing so also prevents it to use its other abilities in the mean time."
              }
          ]
      }
  },
  {
      "name": "Sharkoak",
      "image": {
          "png": require("../Assets/Ghosts/lineup/115_sharkoak.png").default,
          "webp": require("../Assets/Ghosts/lineup/115_sharkoak.webp").default,
          "width": 383,
          "height": 291
      },
      "card": require("../Assets/Ghosts/Cards/SPECTRAL SHARKOAK.jpg").default,
      "infos": {
          "kawaii name": "Gmagia",
          "kaiju name": "Sharkoak",
          "level": "7",
          "family": "Spectral",
          "types": "Stinky / Glutton / Water",
          "nest": "Shark tooth necklace",
          "receptacle": "None",
          "age": "Panic",
          "rarity": "Rare",
          "deplacement": "\u201cSwim\u201d around by levitating. Likes to swim into the ground, with only its backfin poking out menacingly.",
          "Weakness": "As a redoubtable stinky type ghost, Sharkoak has everything that smells good in horror and is sure to get stunned by this kind of scent ! Make sure to use that to your advantage when facing this dangerous glutton.",
          "": "",
          "attacks": [
              {
                  "number": 1,
                  "name": "BREATH OF NIGHTMARE",
                  "text": "In both its Kawa\u00ef and Booster mode, SHARKOAK exhales a foul smelling cloud of stink that is sure to make you balk in disgust. And if he manages to go trough you, that stink will stick to you forever !"
              },
              {
                  "number": 2,
                  "name": "ANCHOR THROW",
                  "text": "In its booster mode, SHARKOAK is able to throw out the two anchors it has at the end of its fins at phenomenal speed. This very powerful and fast attack often allows him to punish an opponent who is too slow, or to attack by surprise by leaping out of a building while attacking."
              },
              {
                  "number": 3,
                  "name": "JAWS FROM HELL (unused in episode)",
                  "text": "SHARKOAK is a huge glutton and attack by opening up its huge jaws to literally gulp down its target, turning it into a pile of green slime that spews an unbearable smell and smoke. The bigger the ghost gets, the bigger the things it can swallow. It would be a shame if the Statue of Liberty were turned into a pile of stinky slime, wouldn\u2019t it?"
              }
          ],
          "defenses": [
              {
                  "number": 1,
                  "name": "STINKY CLOUD",
                  "text": "SHARKOAK can shoot out foul-smelling gas from its fins, shrouding itself in a big stinky cloud to evade its enemy."
              },
              {
                  "number": 2,
                  "name": "ANCHOR TORNADO (unused in episode)",
                  "text": "To protect itself, SHARKOAK plants its anchor and moves in circles around it increasingly fast. An greenish tornado of Boo energy appears around it, rising to several dozen feet high and pushing opponents away."
              }
          ]
      }
  },
  {
      "name": "Arakgum",
      "image": {
          "png": require("../Assets/Ghosts/lineup/116_arakgum.png").default,
          "webp": require("../Assets/Ghosts/lineup/116_arakgum.webp").default,
          "width": 383,
          "height": 291
      },
      "card": require("../Assets/Ghosts/Cards/SPECTRAL ARAKGUM.jpg").default,
      "infos": {
          "kawaii name": "Arak",
          "kaiju name": "Arakgum",
          "level": "5",
          "family": "Spectral",
          "types": "Collector / Merger",
          "nest": "Spider\u2019s web",
          "receptacle": "A piece of chewing-gum",
          "age": "Awe",
          "rarity": "Rare",
          "deplacement": "Flight and levitation in both modes.",
          "Weakness": "As ARAK GUM\u2019s webs are made of gum, they\u2019re sensible to temperature change. Freezing or melting them will render the ghost harmless, making its capture easy.",
          "": "",
          "attacks": [
              {
                  "number": 1,
                  "name": "GUMMY WEB",
                  "text": "In its Booster mode, ARAKGUM can weave out threads of spider web, made out of chewing-gum, to entrap its prey and build impressive labyrinths of gummy webs. And beware : those sticky, resilient and strawberry-flavored webs are practically impossible to escape ! Nothing short of freezing temperatures will be able to break you out of it."
              },
              {
                  "number": 2,
                  "name": "GUMMY BLAST",
                  "text": "In its Booster mode, ARAKGUM can shoot chewing gum projectiles that\u2019ll get their target completely stuck. Keep you heads down, lest you get some in your hair !"
              }
          ],
          "defenses": [
              {
                  "number": 1,
                  "name": "WEB ALARM",
                  "text": "In its booster mode, ARAK GUM\u2019s gummy webs act as tripwires, alerting it to the presence of foes on its territory when someone touches one."
              },
              {
                  "number": 2,
                  "name": "GUMMY SHIELD (unused in episode)",
                  "text": "In its booster mode, ARAK GUM can blow a giant bubblegum from its mouth in order to shield itself from incoming attack."
              }
          ]
      }
  },
  {
      "name": "Xhypno",
      "image": {
          "png": require("../Assets/Ghosts/lineup/117_xhypno.png").default,
          "webp": require("../Assets/Ghosts/lineup/117_xhypno.webp").default,
          "width": 383,
          "height": 291
      },
      "card": require("../Assets/Ghosts/Cards/SPECTRAL XHYPNO.jpg").default,
      "infos": {
          "kawaii name": "Arak",
          "kaiju name": "Xhypno",
          "level": "9",
          "family": "Spectral",
          "types": "Hypnotic / Metal / Plant / Wind",
          "nest": "Old butterfly brooch",
          "receptacle": "None",
          "age": "Abomination",
          "rarity": "Rare",
          "deplacement": "Levitation in kawa\u00ef mode. Flight in booster mode.",
          "Weakness": "As resistant as they are, XHYPNO\u2019s crystal are very sensible to sound. Making very high-pitched notes the best way to shatter them and capture this extremely dangerous specimen!",
          "": "",
          "attacks": [
              {
                  "number": 1,
                  "name": "HYPNO CRYSTAL",
                  "text": "In its Kawa\u00ef mode, XHYPNO can mesmerized people by flashing at them the crystal it carries on its back. Its victims, completely enthralled will then starts accumulating quantities of flowers and plants for their new master\u2026 While chanting its name enthusiastically : \u201cXhypnoooooo !\u201d"
              },
              {
                  "number": 2,
                  "name": "HYPNO WINGS",
                  "text": "In its Booster mode, XHYPNO\u2019s grow a multitude of hypnotic crystal on its newly sprouted wings\u2026 Making it able to flash and hypnotize entire areas just by flying over !"
              },
              {
                  "number": 3,
                  "name": "CRYSTAL WINGS",
                  "text": "In its Booster mode, XHYPNO\u2019s newly sprouted wings gets reinforced by the crystals that grow on them\u2026 Making them extremely resilient! It can then use them as blades to slash at everything \u2013 and everyone \u2013 standing in its way."
              },
              {
                  "number": 4,
                  "name": "CRYSTAL BLAST",
                  "text": "In its Booster mode, XHYPNO can shoot dangerous crystal shards out of its mouth, allowing it to attack at range, even as it gets immobilized or decide to shield itself behind its wings."
              }
          ],
          "defenses": [
              {
                  "number": 1,
                  "name": "CRYSTALIDE",
                  "text": "In booster mode, Thanks to the resilient property of its crystal wings, XHYPNO can shield itself from almost any attack coming from the front, just by hiding itself behind them."
              },
              {
                  "number": 2,
                  "name": "CRYSTAL GUST (unused in episode)",
                  "text": "In its booster mode, XHYPNO can knock its foes away with a single flap of its mighty crystal wings."
              },
              {
                  "number": 3,
                  "name": "CRYSTAL COCOONS",
                  "text": "As it finally gets sated with boo energy, Xhypno can unleash it in the form of a multitude of crystalline cocoons that sprout from the flowers pile amassed by its mesmerized victims. Inside those cocoons, an army of newborn Xhypno start to grow\u2026 Menacing to unleash a new generation of ghost onto the world if the original one isn\u2019t stopped in time !"
              }
          ]
      }
  },
  {
      "name": "Artiflame",
      "image": {
          "png": require("../Assets/Ghosts/lineup/118_artiflame.png").default,
          "webp": require("../Assets/Ghosts/lineup/118_artiflame.webp").default,
          "width": 383,
          "height": 291
      },
      "card": require("../Assets/Ghosts/Cards/SPECTRAL ARTIFLAME.jpg").default,
      "infos": {
          "kawaii name": "Flame",
          "kaiju name": "Artiflame",
          "level": "4",
          "family": "Spectral",
          "types": "Explosive / Merger",
          "nest": "A lump of coal",
          "receptacle": "A fireworks rocket",
          "age": "Dread",
          "rarity": "Uncommon",
          "deplacement": "Levitation in Kawa\u00ef mode. Flying around like a Chinese dragon in Booster mode.",
          "Weakness": "In addition to its speed and explosiveness, ARTIFLAMME is a surprisingly resilient ghost. Thus, outside of slowly grinding it down, the best way to capture it is to turn the power of its own rocket against it in order to quickly stun it!",
          "": "",
          "attacks": [
              {
                  "number": 1,
                  "name": "EXPLOBOO",
                  "text": "In its Kawa\u00ef mode, FLAMME can create small sized explosions near itself. While those explosions are fairly harmless and won\u2019t do much damage, they\u2019re still enough to sow chaos and fear all around!"
              },
              {
                  "number": 2,
                  "name": "ROCKETWORK",
                  "text": "In its Booster mode ARTIFLAMME can shoot rockets from the two firework horns on its head. This allows it to use the effect of its EXPLOBOO ability at a distance and with a LOT more fire power, as the explosions from the rockets are so powerful they can knock down almost any opponent!"
              }
          ],
          "defenses": [
              {
                  "number": 1,
                  "name": "ROCKET SPEED",
                  "text": "In its Kawa\u00ef mode, FLAMME is already an impressively fast ghost that propels itself forward with the help of its own explosions. But as it gets into Booster mode, ARTIFLAMME becomes one of the fastest Ghosts around! In both case, this speed allows it to dodge and overcome its opponents\u2026 Simply by flying away so fast they can\u2019t hope to catch up!"
              }
          ]
      }
  },
  {
      "name": "Sporofungus",
      "image": {
          "png": require("../Assets/Ghosts/lineup/120_sporofungus.png").default,
          "webp": require("../Assets/Ghosts/lineup/120_sporofungus.webp").default,
          "width": 383,
          "height": 291
      },
      "card": require("../Assets/Ghosts/Cards/MOLECULAR SPOROFUNGUS.jpg").default,
      "infos": {
          "kawaii name": "Flame",
          "kaiju name": "Sporofungus",
          "level": "4",
          "family": "Molecular",
          "types": "Plant / Toxic",
          "nest": "An old dirty tin can",
          "receptacle": "None",
          "age": "Dread",
          "rarity": "Uncommon",
          "deplacement": "Levitation in both its kawa\u00ef and booster mode.",
          "Weakness": "As SPOROFUNGUS is impervious to damage while it stays inside its mushroom cap, the only way to defeat it is to time one\u2019s attacks for when the ghost emerges from its shelter\u2026 Usually right before using its Fungal Fog ability!",
          "": "",
          "attacks": [
              {
                  "number": 1,
                  "name": "FUNGAL BREATH",
                  "text": "in its Kawai form, SPOROFUNGUS can exhale disgusting toxic fumes that linger around and infect people with icky, ugly, dirty pimples and terrible stinky breath\u2026 That smell worse that a rotten fungus!"
              },
              {
                  "number": 2,
                  "name": "FUNGAL FOG",
                  "text": "In its booster mode, SPOROFUNGUS becomes able to shoot out giant clouds of toxic fog, that engulfs entire places in mere seconds\u2026 Turning anyone breathing those fumes inside into actual fungus!"
              }
          ],
          "defenses": [
              {
                  "number": 1,
                  "name": "HAT TRICK",
                  "text": "In his booster mode, SPOROFUNGUS becomes able to retreat inside its own mushroom cap, making itself practically invulnerable in the process! Fortunately, it is also unable to use any other abilities while it stays safely inside\u2026 Forcing it to come out whenever it wishes to attack."
              }
          ]
      }
  },
  {
      "name": "Katastrophe",
      "image": {
          "png": require("../Assets/Ghosts/lineup/123_katastrophe.png").default,
          "webp": require("../Assets/Ghosts/lineup/123_katastrophe.webp").default,
          "width": 383,
          "height": 291
      },
      "card": require("../Assets/Ghosts/Cards/STRANGE KATASTROPHE.jpg").default,
      "infos": {
          "kawaii name": "Bouncy",
          "kaiju name": "Katastrophe",
          "level": "7",
          "family": "Strange",
          "types": "Triplet / Shrieker / Chaotic",
          "nest": "A porcelain vase",
          "receptacle": "None",
          "age": "Panic",
          "rarity": "Very rare",
          "deplacement": "Levitating",
          "Weakness": "As a Shrieker type Ghost, KATASTROPHE relies on the three mouths of its three heads to attack. So the best way to shut it down\u2026 Is to shut them all up!",
          "": "",
          "attacks": [
              {
                  "number": 1,
                  "name": "KITTY GOO",
                  "text": "In its booster mode, KATASTROPHE\u2019s middle head can spray out a disgusting and sticky pink goo that\u2019ll get you stuck on the spot if you get hit. That middle head might be cute\u2026 But it has one dirty breath!"
              },
              {
                  "number": 2,
                  "name": "KITTY DRAIN",
                  "text": "In its booster mode, KATASTROPHE\u2019s right head can shoot out a draining cone, that\u2019ll suck the Boo energy out from anyone caught in it. That right head might be cute\u2026 But it\u2019s starving for some Boo energy!"
              },
              {
                  "number": 3,
                  "name": "KITTY COMBO",
                  "text": "In its booster mode, once they\u2019ve all fused together into one single entity, KATASTROPHE\u2019s three heads can combine their KITTY GOO, DRAIN and HOWL into one devastating howling attack that will leave their prey empty, stunned and sticky. Or in another word : vanquished!"
              }
          ],
          "defenses": [
              {
                  "number": 1,
                  "name": "KITTY SCREECH",
                  "text": "In their Kawa\u00ef mode, the three independent heads that constitute KATASTROPHE can join together into a screeching chant that\u2019ll leave anyone hearing it stun for a beat. Better cover your ears!"
              },
              {
                  "number": 2,
                  "name": "KITTY HOWL",
                  "text": "In its booster mode, KATASTROPHE\u2019s left head can use its howl to shoot out a sound wave that\u2019ll knock anyone back. That left head might be cute\u2026 But it really don\u2019t want you to get close!"
              }
          ]
      }
  },
  {
      "name": "Chronoklok",
      "image": {
          "png": require("../Assets/Ghosts/lineup/124_chronoklok.png").default,
          "webp": require("../Assets/Ghosts/lineup/124_chronoklok.webp").default,
          "width": 383,
          "height": 291
      },
      "card": require("../Assets/Ghosts/Cards/STRANGE CHRONOKLOK.jpg").default,
      "infos": {
          "kawaii name": "Petrifear",
          "kaiju name": "Chronoklok",
          "level": "5",
          "family": "Strange",
          "types": "Petrifier / Merger",
          "nest": "A rubix cube",
          "receptacle": "A smart watch",
          "age": "Awe",
          "rarity": "Rare",
          "deplacement": "Levitating",
          "Weakness": "CHRONOKLOK\u2019s powers originate from the internal gears of the smart watch it merges with. Meaning the best way to defeat it is to drop a proverbial monkey wrench in it!",
          "": "",
          "attacks": [
              {
                  "number": 1,
                  "name": "PETRICHARGE",
                  "text": "In its Kawa\u00ef mode, PETRIFEAR is able to petrify anyone its passes through, bringing them to a complete stop still. It\u2019s like its victims are frozen in time!"
              },
              {
                  "number": 2,
                  "name": "TIMESTOP BUBBLE",
                  "text": "In its Booster mode, CHRONOKLOK is able to charge up its petrifying power and to release it after a delay in the form of a giant petrifying sphere, in which everything grinds to a halt\u2026 Even time itself!"
              }
          ],
          "defenses": [
              {
                  "number": 1,
                  "name": "STRONG ARMS",
                  "text": "In booster mode, CHRONOKLOK can use the steel reinforced bracelet of the watch it merged with as arms with which it can protect itself from incoming attack. Since the watch was built with the intent of being super-resilient, CHRONOKLOK can withstand many attacks this way!"
              },
              {
                  "number": 2,
                  "name": "COLLINS\u2019 TAUNTS",
                  "text": "In booster mode, CHRONOKLOK can use the pre-recorded quotes of Basketball Superstar Michael Collins to taunt at his opponents\u2026 Making them lose their temper and their focus!"
              }
          ]
      }
  },
  {
      "name": "Jellysterry",
      "image": {
          "png": require("../Assets/Ghosts/lineup/127_jellysterry.png").default,
          "webp": require("../Assets/Ghosts/lineup/127_jellysterry.webp").default,
          "width": 383,
          "height": 291
      },
      "card": require("../Assets/Ghosts/Cards/VISCOUS JELLYSTERRY.jpg").default,
      "infos": {
          "kawaii name": "Petrifear",
          "kaiju name": "Jellysterry",
          "level": "4",
          "family": "Viscous",
          "types": "Toxic / Bouncer (then: Ice / Bouncer mid episode)",
          "nest": "A bouncing ball",
          "receptacle": "None",
          "age": "Dread",
          "rarity": "Uncommon",
          "deplacement": "Floating and bouncing.",
          "Weakness": "Due to its unique ADAPTATIVE JELLY ability, Jellystery poses a unique challenge in that it\u2019ll always prove be resilient to the first strategy used against it. Thus, to capture it, you\u2019ll need to stage a two step strategy : first, give it a new type. Two\u2026 Use that new type\u2019s weakness against it!",
          "": "",
          "attacks": [
              {
                  "number": 1,
                  "name": "JELLY CHARGE",
                  "text": "In both its Kawa\u00ef and Booster mode, JELLYSTERY is able to turn anyone its passes through into icky, sticky jelly! Urk. Don\u2019t eat those. They taste awful\u2026 And they\u2019re still people!"
              },
              {
                  "number": 2,
                  "name": "JELLY BOUNCE",
                  "text": "In its Booster mode, JELLYSTERY can throw itself at any solid surface to produce a shockwave of Boo energy that turn anyone it encounters into jelly!"
              },
              {
                  "number": 3,
                  "name": "FROZEN JELLY CHARGE",
                  "text": "In both its Kawa\u00ef and Booster mode, JELLYSTERY is able to turn anyone its passes through into icy, frozen jelly! Urk. Still don\u2019t eat those. They still taste awful\u2026 And they\u2019re still still people!"
              },
              {
                  "number": 4,
                  "name": "FROZEN JELLY BOUNCE",
                  "text": "In its Booster mode, JELLYSTERY can throw itself at any solid surface, bouncing back up to produce a shockwave of Boo energy that turn anyone it encounters into frozen jelly!"
              }
          ],
          "defenses": [
              {
                  "number": 1,
                  "name": "ADAPTATIVE JELLY",
                  "text": "In both its Kawa\u00ef and Booster mode, JELLYSTERY has unique adaptive properties that allow it to modify its primary type when it gets it by an elemental attack for the first time after waking up\u2026 Adopting the type of the attack used! (ie: By getting hit by an ice attack first, it\u2019ll become an ICE type Ghost.)"
              }
          ]
      }
  },
  {
      "name": "Burghorror",
      "image": {
          "png": require("../Assets/Ghosts/lineup/130_burghorror.png").default,
          "webp": require("../Assets/Ghosts/lineup/130_burghorror.webp").default,
          "width": 383,
          "height": 291
      },
      "card": require("../Assets/Ghosts/Cards/VISCOUS BURGHORROR.jpg").default,
      "infos": {
          "kawaii name": "Spig",
          "kaiju name": "Burghorror",
          "level": "4",
          "family": "Viscous",
          "types": "Chaotic / Merger",
          "nest": "A cheese-sauce bottle",
          "receptacle": "A burger",
          "age": "Dread",
          "rarity": "Rare",
          "deplacement": "Floating around",
          "Weakness": "Thanks to its ability to split itself into different layers, BURGHORROR can be quite hard to reliably hit. Meaning the best strategy to defeat it is often to just tie all its layers together\u2026 Maybe with some flexy threads ?",
          "": "",
          "attacks": [
              {
                  "number": 1,
                  "name": "MELTED GOO",
                  "text": "In its Kawa\u00ef mode, SPIG can spit out projectiles made out of melted goo that are sure to leave a dirty stain wherever \u2013 or on whomever \u2013 they land! Disgusting\u2026"
              },
              {
                  "number": 2,
                  "name": "BURGER BLAST",
                  "text": "In its booster mode, BURGHORROR can now spit out huge chunk of burgers to its adversaries in a chaotic swarm of food projectiles. Frisbee Tomatoes, Pickle Torpedo and others are all on the menu! The worst projectiles type from this attack are BURGHORROR\u2019s signature : the Melted Cheese Blast ! As they\u2019ll leave their target completely stuck in a blanket of warm and smelly cheese. Disturbing\u2026 Yet delicious!"
              }
          ],
          "defenses": [
              {
                  "number": 1,
                  "name": "BURGER SPLIT",
                  "text": "In its booster mode, BURGHORROR can dissociate the different layers of its burger, like an accordion unfolding... Allowing it to dodge incoming attacks by splitting its layers up!"
              }
          ]
      }
  },
  {
      "name": "Agia",
      "image": {
          "png": require("../Assets/Ghosts/lineup/131_agia.png").default,
          "webp": require("../Assets/Ghosts/lineup/131_agia.webp").default,
          "width": 383,
          "height": 291
      },
      "card": require("../Assets/Ghosts/Cards/FLEXIBLE AGIA.jpg").default,
      "infos": {
          "kawaii name": "Elatrick",
          "kaiju name": "Agia",
          "level": "7",
          "family": "Flexible",
          "types": "Merger / Hypnotic / Prankster",
          "nest": "A pi\u00f1ata",
          "receptacle": "An inextinguishable birthday candle",
          "age": "Panic",
          "rarity": "Rare",
          "deplacement": "Levitating.",
          "Weakness": "As AGIA\u2019s power originate from the flame of its candle, extinguishing it is the obvious way to defeat it. But like any self-respecting birthday candle, AGIA\u2019s one keep relighting itself! So, in order to put a definitive end to this non-sense, you might have to encase it in something truly hermetic\u2026 Like some fractal boo!",
          "": "",
          "attacks": [
              {
                  "number": 1,
                  "name": "ELASTO PUNCH",
                  "text": "In its Kawa\u00ef mode, ELASTRICK\u2019s arms can stretch endlessly and turns anyone they touch into \u201crubber\u201d. Their victims shrivel up, stretch and squash for a few second before returning to their normal self\u2026 And while the effects are only temporary, they\u2019re usually enough to terrorize anyone affected!"
              },
              {
                  "number": 2,
                  "name": "AGEMINUS",
                  "text": "In its Booster mode, when the flame of its candle burns red, AGIA\u2019s stretchable arms can throw punches that send their victims back into childhood. The punches will start by applying the temporary effect of ELASTO PUNCH, before hypnotizing the victim into behaving like a very young child\u2026 Even though its body remains the same. Except a lot of playing, giggling and crying for toys with this one!"
              },
              {
                  "number": 3,
                  "name": "AGEPLUS",
                  "text": "In its Booster mode, when the flame of its candle burns blue, AGIA\u2019s stretchable arms can throw punches that send their victims straight to the elder age. The punches will start by applying the temporary effect of ELASTO PUNCH, before hypnotizing the victim into behaving like some very old people\u2026 Even though its body remains the same. Expect a lot of slow-walking, complaining about the youth and talking about the weather with this one!"
              }
          ],
          "defenses": [
              {
                  "number": 1,
                  "name": "BODY STRETCH",
                  "text": "In both its Kawa\u00ef and Booster mode, AGIA\u2019s flexible body can stretch out in every way, making it very easy for it to dodge incoming attacks!"
              },
              {
                  "number": 2,
                  "name": "PRANKANDLE",
                  "text": "In its Booster mode, AGIA\u2019s merged with an inextinguishable birthday candle, that can change color to switch between AGIA\u2019s two offensive abilities : AGEMINUS and AGEPLUS. What\u2019s worse: the candle just keep relighting itself every time it gets extinguished! Making it very hard to stop the ghost from using its powers."
              }
          ]
      }
  },
  {
      "name": "Vochaos",
      "image": {
          "png": require("../Assets/Ghosts/lineup/132_vochaos.png").default,
          "webp": require("../Assets/Ghosts/lineup/132_vochaos.webp").default,
          "width": 383,
          "height": 291
      },
      "card": require("../Assets/Ghosts/Cards/VISCOUS VOCHAOS.jpg").default,
      "infos": {
          "kawaii name": "Elatrick",
          "kaiju name": "Vochaos",
          "level": "4",
          "family": "Viscous",
          "types": "Chaotic / Sound",
          "nest": "An old audio recorder",
          "receptacle": "None",
          "age": "Dread",
          "rarity": "Rare",
          "deplacement": "Levitating\u2026 And spinning around, tongue\u2019s out!",
          "Weakness": "As VOCHAOS\u2019s powers all originate from its tongue, the best way to neutralizing the ghost is by immobilizing it completely\u2026 By coating it with some Fractal Boo, for example!",
          "": "",
          "attacks": [
              {
                  "number": 1,
                  "name": "MINILICK",
                  "text": "In its Kawa\u00ef mode, when VOCHAOS passes trough people, he can randomly modify their voices, giving its victim very high or very low pitch\u2026 Sounds scary?"
              },
              {
                  "number": 2,
                  "name": "MAXILICK",
                  "text": "In its Booster mode, VOCHAOS can use its humongous and disgusting tongue to attack people, by giving them a good lick and robbing them of their voices. An attack that is sure to leave you\u2026 Speechless!"
              }
          ],
          "defenses": [
              {
                  "number": 1,
                  "name": "SPINILICK",
                  "text": "In Booster mode, VOCHAOS can send itself and its tongue spinning around, to form a veritable whirlwind of spits n\u2019 licks! Affecting anyone coming too close to him with its MAXILICK ability."
              }
          ]
      }
  },
  {
      "name": "Batata",
      "image": {
          "png": require("../Assets/Ghosts/lineup/BATATA.png").default,
          "webp": require("../Assets/Ghosts/lineup/BATATA.webp").default,
          "width": 383,
          "height": 291
      },
      "card": require("../Assets/Ghosts/Cards/MOLECULAR BATATA.jpg").default,
      "infos": {
          "kawaii name": "Torsand",
          "kaiju name": "Batata",
          "level": "4",
          "family": "Molecular",
          "types": "Summoner / Night",
          "nest": "A shadowy crystal orb",
          "receptacle": "None",
          "age": "Dread",
          "rarity": "Rare",
          "deplacement": "Flying like a bat !",
          "Weakness": "Like most Night Type ghost, BATATA relies on the cover of darkness to thrive and is especially susceptible to bright lights. Thus, the best way to defeat it is to force it to face the sun by tricking it to come out of its NIGHT REALM during day time\u2026 Or to bring a powerful source of light inside it to flash the ghost with!",
          "": "",
          "attacks": [
              {
                  "number": 1,
                  "name": "BAT CURSE",
                  "text": "In both its Kawa\u00ef and booster mode, BATATA is able to turn anyone its touches into a cursed bat that\u2019ll follow it around and obey its every commands. What\u2019s worse, those bats can spread the curse to other people they get in contact with\u2026 Quickly assembling a giant swarm of bats to serve BATATA!"
              },
              {
                  "number": 2,
                  "name": "NIGHT REALM",
                  "text": "In its booster mode, once its capped on fear, BATATA can activates its NIGHT REALM ability to create a gigantic sphere of darkness, that extinguishes all light inside to create an eternal night\u2026 And also drains the Boo energy of anyone standing inside!"
              }
          ],
          "defenses": [
              {
                  "number": 1,
                  "name": "WING SHIELD",
                  "text": "In booster mode, BATATA can use its massive wing to shield itself from incoming attack."
              }
          ]
      }
  },
  {
      "name": "Bubble Brush",
      "image": {
          "png": require("../Assets/Ghosts/lineup/BUBBLE BRUSH.png").default,
          "webp": require("../Assets/Ghosts/lineup/BUBBLE BRUSH.webp").default,
          "width": 383,
          "height": 291
      },
      "card": require("../Assets/Ghosts/Cards/FLEXIBLE BUBBLE BRUSH.jpg").default,
      "infos": {
          "kawaii name": "Bubbleboo",
          "kaiju name": "Bubble-Brush",
          "level": "4",
          "family": "Flexible",
          "types": "Water / Merger",
          "nest": "A fire-extinguisher",
          "receptacle": "A broken washing-machine",
          "age": "Dread",
          "rarity": "Uncommon",
          "deplacement": "Levitation",
          "Weakness": "BUBBLE-BRUSH most potent power is with no doubt its BUBBLE BEAM ability. It is, in fact, so potent, that should the ghost find itself unable to unleash it after loading it\u2026 It would self-implode into a soapy mess! So the best way to capture it? Lock its door down!",
          "": "",
          "attacks": [
              {
                  "number": 1,
                  "name": "BUBBLE RUBS",
                  "text": "In its Kawa\u00ef mode, BUBBLEBOO has the nasty habit of rubbing its bubbly hands all over everything and everyone\u2019s face, which makes its surrounding all wet and soapy and its victim all frothy and bubbly at the mouth! In its booster mode, this power gets even more potent, allowing BUBBLEBRUSH to wash over entire areas with its soapy foam!"
              },
              {
                  "number": 2,
                  "name": "BUBBLE BEAM",
                  "text": "In its booster mode, BUBBLE-BRUSH can shoot out a massive and overpowered beam of water and soap at its foes. This extremely potent ability originates from the front-loading door of its machine-washing like body and does require a loading time, as BUBBLE-BRUSH needs to concentrate its Boo energy within its spinning drum before releasing it all at once. This prevents it from using it too often and forces it to wait between each use."
              }
          ],
          "defenses": [
              {
                  "number": 1,
                  "name": "SPINNING BRUSH",
                  "text": "In its booster mode, BUBBLE-BRUSH\u2019s hands extend into two spinning brush, that allows it not only to apply its BUBBLE RUBS with a area of effect\u2026 But can also be used to deflect incoming projectiles, thanks to their fast spinning properties!"
              }
          ]
      }
  },
  {
      "name": "Biballoon",
      "image": {
          "png": require("../Assets/Ghosts/lineup/Biballoon.png").default,
          "webp": require("../Assets/Ghosts/lineup/Biballoon.webp").default,
          "width": 383,
          "height": 291
      },
      "card": require("../Assets/Ghosts/Cards/STRANGE BIBALLOON.jpg").default,
      "infos": {
          "kawaii name": "Glups",
          "kaiju name": "Biballoon",
          "level": "5",
          "family": "Strange",
          "types": "Wind / Multiplier",
          "nest": "Helium tank",
          "receptacle": "None",
          "age": "Awe",
          "rarity": "Very rare",
          "deplacement": "Float around like a big balloon and/or undulate like a snake-shaped balloon.",
          "Weakness": "Contrary to what may appears obvious in the first place, BIBALOON\u2019s abilities don\u2019t originate from its mouth, but from the back balloon of its body. Meaning that tying its mouth up won\u2019t be enough to defeat it\u2026 You\u2019ll also have to tie its rear end up!",
          "": "",
          "attacks": [
              {
                  "number": 1,
                  "name": "HUFF N\u2019 PUFF",
                  "text": "In its Kawa\u00ef mode, BIBALOON can turn people into people-shaped balloon just by blowing air on them. So take care not to stand in front of it while it huffs and puffs\u2026 Or it\u2019ll blow you up!"
              },
              {
                  "number": 2,
                  "name": "BLOW HARD",
                  "text": "In its Booster mode, BIBALOON can accumulate so much wind in its back-balloon that its HUFF N\u2019 PUFF upgrades into BLOW HARD. BIBALOON then becomes able to blow air over entire city block with just one breath! The gust resulting from this ability is so strong, it\u2019ll knock everything over in addition to turning the people caught in it into balloons."
              }
          ],
          "defenses": [
              {
                  "number": 1,
                  "name": "NINETY-NINE PLUS BALLONS",
                  "text": "In its Kawa\u00ef mode, BIBALOON can split itself into a multitude of copies that can all act independently from one another. And while those copies might not always share the same colors, they all have one thing in common : they\u2019ll converge into one mighty giant ghost when BIBALOON finally goes into booster mode!"
              },
              {
                  "number": 2,
                  "name": "BOUNCY SKIN",
                  "text": "In its booster mode, BIBALOON\u2019s skin becomes so stretchy, that most attacks will bounce back off it and be sent back to the attacker. So watch out\u2026 Or you might get hit by your own arrows!"
              }
          ]
      }
  },
  {
      "name": "Chaorion",
      "image": {
          "png": require("../Assets/Ghosts/lineup/CHAORION.png").default,
          "webp": require("../Assets/Ghosts/lineup/CHAORION.webp").default,
          "width": 383,
          "height": 291
      },
      "card": require("../Assets/Ghosts/Cards/MOLECULAR CHAORION.jpg").default,
      "infos": {
          "kawaii name": "Petrifear",
          "kaiju name": "Chaorion",
          "level": "9",
          "family": "Molecular",
          "types": "Alien / Hypnotic / Chaotic / Metal",
          "nest": "Asteroid",
          "receptacle": "None",
          "age": "Abomination",
          "rarity": "Unique",
          "deplacement": "Floating around with an Alien vibe",
          "Weakness": "As if fighting an overpowered Alien Ghost from outer space wasn\u2019t enough, CHAORION also comes with a non-negligible added risk of destroying earth through a giant asteroid crash! Luckily, blowing-up said asteroid before it hit should be enough to distract and distraught the ghost, giving you a shot a capturing it\u2026 So get ready to fly out to space!",
          "": "",
          "attacks": [
              {
                  "number": 1,
                  "name": "ALIEN GAZE",
                  "text": "In both its Kawa\u00ef and Booster mode, CHAORION can mesmerized people by flashing its only eye at them. Its victims, completely enthralled, will then starts spreading the goo from its ALIEN GOO ability all around\u2026 While chanting the name of their new enthusiastically : \u201cChao-rion ! Chao-rion !\u201d"
              },
              {
                  "number": 2,
                  "name": "ALIEN BLADES",
                  "text": "In its Booster mode CHAORION grows out two blade made out of unknown - but extremely tough \u2013 Alien Metal. It can then use those blades offensively, to whack and slash at anyone opposing it."
              },
              {
                  "number": 3,
                  "name": "ALIEN BLADE STORM",
                  "text": "In its Booster mode CHAORION can spin its two Alien Blades around itself at full speed to create a storm of blows not unlike that of a circular chainsaw. Beware, as this attack is sure to take you down if un-countered!"
              },
              {
                  "number": 4,
                  "name": "COMET CRASH",
                  "text": "In its Booster mode, once it finally gets capped on boo energy, CHAORION can unleash it by activating the Alien Symbol drawn by its hypnotized followers. The symbol then acts as beacon, summoning and attracting a giant asteroid\u2026 That starts hurtling towards earth at full speed!  And if that wasn\u2019t enough, the asteroid also packs another surprise in the form of CHAORION\u2019s COMET COCOONS ability. Whoopsie."
              }
          ],
          "defenses": [
              {
                  "number": 1,
                  "name": "ALIEN GOO",
                  "text": "In its Booster mode CHAORION can shoot out a giant beam that creates a pool of strange Alien Goo made out of pure Boo energy. People hypnotize by its ALIEN GAZE ability will then start spreading it all over the place, to draw weird Alien Symbols on the ground\u2026 Spooky!"
              },
              {
                  "number": 2,
                  "name": "ALIEN BLADE DANCE",
                  "text": "In its booster mode CHAORION can spin its two Alien Blades around itself at full speed to create a kinetic field that not only shield it from incoming attack\u2026 But also as a chance to bounce those same attacks back at the senders!"
              },
              {
                  "number": 3,
                  "name": "COMET COCOONS",
                  "text": "As it unleashes it\u2019s COMET CRASH ability, CHAORION also activates its COMET COCOONS one\u2026 In the form of a multitude of alien cocoons that sprout all over the summoned asteroid. Inside those cocoons, an army of newborn CHAORION start to grow. It menaces to unleash a new generation of alien ghost onto the world, right after the asteroid impact, if the original ghost isn\u2019t stopped in time!"
              }
          ]
      }
  },
  {
      "name": "Creepop",
      "image": {
          "png": require("../Assets/Ghosts/lineup/CREEPOP.png").default,
          "webp": require("../Assets/Ghosts/lineup/CREEPOP.webp").default,
          "width": 383,
          "height": 291
      },
      "card": require("../Assets/Ghosts/Cards/SPECTRAL CREEPOP.jpg").default,
      "infos": {
          "kawaii name": "Gringag",
          "kaiju name": "Creepop",
          "level": "7",
          "family": "Spectral",
          "types": "Prankster / Musical / Merger",
          "nest": "A confetti tube",
          "receptacle": "A boom box",
          "age": "Panic",
          "rarity": "Uncommon",
          "deplacement": "Levitation",
          "Weakness": "As CREEPOP powers are all tied to its ability to produce music from its speaker\u2026 Shutting those off is a sure-fire way to shut him off!",
          "": "",
          "attacks": [
              {
                  "number": 1,
                  "name": "GRIMA CHARGE",
                  "text": "In its Kawaii mode, GRINGAG can curses anyone it passes through the people, making them freeze in grotesque positions, grimacing with fear. Such a funny lil\u2019 guy, right?"
              },
              {
                  "number": 2,
                  "name": "DANCING BEAM",
                  "text": "In its Booster mode, CREEPOP can shoot boo energy beams from the jack cables on its back. Anyone hit by those will find itself trap in an indestructible bubble of boo energy, within which they\u2019re forced to dance for eternity. Spooky\u2026 But groovy!"
              },
              {
                  "number": 3,
                  "name": "WHIPPING CABLES",
                  "text": "In its Booster mode, CREEPOP can extends the jack cables on its back to use them like whips. Beware when you approach it\u2026 Or get ready for a whoopin\u2019!"
              }
          ],
          "defenses": [
              {
                  "number": 1,
                  "name": "DANCING BOO",
                  "text": "Where most Ghosts have to feed on fear to power up their attacks, CREEPOP is also able to convert the vibes released by people dancing into Boo energy. This allows it to power itself up\u2026 Just by making the music loud!"
              },
              {
                  "number": 2,
                  "name": "BOO SPEAKERS",
                  "text": "In its booster mode, CREEPOP naturally generates music from the speakers on its body. What\u2019s more, it can amplify the effect of its others powers by upping the volume of its own music\u2026 Allowing it to setup its very own feed-back loop!"
              }
          ]
      }
  },
  {
      "name": "Criangle",
      "image": {
          "png": require("../Assets/Ghosts/lineup/CRIANGLE.png").default,
          "webp": require("../Assets/Ghosts/lineup/CRIANGLE.webp").default,
          "width": 383,
          "height": 291
      },
      "card": require("../Assets/Ghosts/Cards/FRACTAL CRIANGLE.jpg").default,
      "infos": {
          "kawaii name": "Droplet",
          "kaiju name": "Criangle",
          "level": "4",
          "family": "Fractal",
          "types": "Geometric / Illusionist",
          "nest": "A modern art sculpture",
          "receptacle": "None",
          "age": "Dread",
          "rarity": "Uncommon",
          "deplacement": "Levitating and spinning around like a spinning top !",
          "Weakness": "As CRIANGLE activates its power by spinning on itself, the best way to capture it is by locking it in place. A task that\u2019ll become incredibly difficult if it manages to create and take refuge in its geometric world.",
          "": "",
          "attacks": [
              {
                  "number": 1,
                  "name": "GEOMETRIZATION CHARGE / GEOMETRIZATION GAZE",
                  "text": "In its Kawa\u00ef mode, CRIANGLE can go through people to \u201cgeometrize\u201d them by turning them into various sentient geometric shapes. As it goes into booster mode, this ability evolves into an eyebeam CRIANGLE can shoot from its eyes\u2026 Allowing it to also geometrize its victims at a distance !"
              },
              {
                  "number": 2,
                  "name": "GEOMETRIC WORLD",
                  "text": "In its Booster mode, CRIANGLE can \u201cdraw\u201d a geometric pattern on the ground by drilling point of Boo energy into it. Once the pattern is fully drawn, huge geometric shapes fly out of it to form a GIANT CRIANGLE ILLUSION that engulf the entire area it stands on. And while that giant CRIANGLE is really just an illusion\u2026 It does turn everything inside it into geometric forms, creating a true geometric world in which the real CRIANGLE will truly feel at home !"
              }
          ],
          "defenses": [
              {
                  "number": 1,
                  "name": "SPINNING TOP",
                  "text": "In his Booster mode, CRIANGLE\u2019s different body part spin on themselves, making it hard to keep it in place\u2026 And allows it to split itself into tranches to dodge incoming attacks !"
              }
          ]
      }
  },
  {
      "name": "Cyclopee",
      "image": {
          "png": require("../Assets/Ghosts/lineup/CYCLOPEE.png").default,
          "webp": require("../Assets/Ghosts/lineup/CYCLOPEE.webp").default,
          "width": 383,
          "height": 291
      },
      "card": require("../Assets/Ghosts/Cards/STRANGE CYCLOPEE.jpg").default,
      "infos": {
          "kawaii name": "Elatrick",
          "kaiju name": "Cyclopee",
          "level": "5",
          "family": "Strange",
          "types": "Cloner / Alien",
          "nest": "Mirror",
          "receptacle": "None",
          "age": "Awe",
          "rarity": "Uncommon",
          "deplacement": "Levitating",
          "Weakness": "Cyclopee loves nothing more than to copy any power you use against it to turn them against you! As such, it\u2019s a good idea to refrain yourself from using every tricks you know\u2026 Since the more moves you show it, the harder it\u2019ll get to deboost it. And since its cloning ability comes from its only eye, blinding could be a great way to neutralize it!",
          "": "",
          "attacks": [
              {
                  "number": 1,
                  "name": "COPY N\u2019 CLONE",
                  "text": "In its Kawa\u00ef mode, CYCLOPEE is able to copy and clone any solid object it sees by flashing its only eye at it. While this power can quickly sow chaos and terrify passers-by, CYCLOPEE luckily has little to no memory: as soon as the ghost copies a new object, he can no longer reproduce the previous one\u2026 Though in its Booster mode, this ability does evolves into an even more potent version that allows CYCLOPEE to keep memories of everything it copied before."
              },
              {
                  "number": 2,
                  "name": "BOO PASTE",
                  "text": "In its Booster mode, CYCLOPEE\u2019s can clone any Boo energy it copied and stored in its memory thanks to its COPY BOO ability. This allows it to use the powers it copied for itself\u2026 And to turn them against their owners!"
              }
          ],
          "defenses": [
              {
                  "number": 1,
                  "name": "COPY BOO",
                  "text": "In its Booster mode, CYCLOPEE\u2019s can clone all the Boo energies it sees, in order to latter re-use them with the help of its BOO PASTE ability. So beware not to show it what your powers can really do\u2026 Lest it does it to you!"
              },
              {
                  "number": 2,
                  "name": "ALIEN SKIN",
                  "text": "In Booster mode, CYCLOPEE can rely on the naturally resilient nature of its Alien skin to withstand a lot of incoming damage."
              }
          ]
      }
  },
  {
      "name": "Cookieflame",
      "image": {
          "png": require("../Assets/Ghosts/lineup/Cookieflame.png").default,
          "webp": require("../Assets/Ghosts/lineup/Cookieflame.webp").default,
          "width": 383,
          "height": 291
      },
      "card": require("../Assets/Ghosts/Cards/SPECTRAL COOKIEFLAME.jpg").default,
      "infos": {
          "kawaii name": "Flamagma",
          "kaiju name": "Cookieflame",
          "level": "6",
          "family": "Spectral",
          "types": "Magma / Merger",
          "nest": "A box of matches",
          "receptacle": "A cookie",
          "age": "Horror",
          "rarity": "Uncommon",
          "deplacement": "Levitation",
          "Weakness": "Due to COOKIEFLAMME regenerative power and ability to melt away any form of Boo energy coating, it is foolish to hope to overcome it through frontal attack. However, if it is great at heating itself up, it is TERRIBLE at cooling itself down\u2026 So just provoke it enough, and that hotheaded ghost is sure to quickly burn itself out!",
          "": "",
          "attacks": [
              {
                  "number": 1,
                  "name": "HEAT BLAST",
                  "text": "In its Kawaii mode, FLAMAGMA can release a blast of heat around itself, suddenly raising the temperature, burning people\u2019s eyebrow, boiling water and just generally sowing chaos and fear all around the place!"
              },
              {
                  "number": 2,
                  "name": "CHOCOLCANO",
                  "text": "In its Booster mode, COOKIEFLAMME can get into eruption, blowing melted chocolate chips everywhere like the crazed volcanic cookie it is! As it does, its body heat raises formidable temperature, forcing it to cool down for a while after each use of this ability."
              },
              {
                  "number": 3,
                  "name": "MAGMA DASH (unused in episode)",
                  "text": "In its Booster mode, COOKIEFLAMME can send itself in a mighty charge that\u2019ll project it forward and will leave everything under its path burned to a crisp!"
              }
          ],
          "defenses": [
              {
                  "number": 1,
                  "name": "UNSTOPPABLE HEAT",
                  "text": "In its Booster mode, COOKIEFLAMME can raises its body temperature to insane level\u2026 Allowing it to break out of any kind of Boo energy coating one might manage to lock it in, just by melting it away! Thought you could just cool that hothead down? Think again!"
              },
              {
                  "number": 2,
                  "name": "CHOCOLATE REGENERATION",
                  "text": "In its Booster mode, COOKIEFLAMME naturally regenerates the Boo chocolate from its chocolate chips\u2026 Making sure both that it\u2019ll never run out of the good stuff, and that it\u2019ll be able to regenerate damages even during a fight!"
              }
          ]
      }
  },
  {
      "name": "Dunky Boss",
      "image": {
          "png": require("../Assets/Ghosts/lineup/DUNKY BOSS.png").default,
          "webp": require("../Assets/Ghosts/lineup/DUNKY BOSS.webp").default,
          "width": 383,
          "height": 291
      },
      "card": require("../Assets/Ghosts/Cards/STRANGE DUNKY BOSS.jpg").default,
      "infos": {
          "kawaii name": "Bouncy",
          "kaiju name": "Dunky Boss",
          "level": "9",
          "family": "Strange",
          "types": "Magma / Bouncer / Explosive / Merger",
          "nest": "Resting in a boocap",
          "receptacle": "Basketball",
          "age": "Abomination",
          "rarity": "Unique",
          "deplacement": "Levitation\u2026 And bouncing around!",
          "Weakness": "As a bouncing ghost, BOUNCY is already obsessed with everything spheric and bouncy. But as a bouncing ghost merged with a basketball, BOUNCY BOSS turns this obsession to one hundred and becomes absolutely manic for basketball! Use that to your advantage\u2026 And lure it into a trap!",
          "": "",
          "attacks": [
              {
                  "number": 1,
                  "name": "LAVA BOUNCE",
                  "text": "In both its Kawa\u00ef and Booster mode, BOUNCY can throw itself toward solid surfaces, where it uses its bouncing properties to propels itself back without any damage\u2026 While leaving behind a pool of molten lava at the point of impact!"
              },
              {
                  "number": 2,
                  "name": "FIRE DUNK",
                  "text": "In its Booster mode, DUNKY BOSS can conjure giant magmatic basketballs inside its hands, which it\u2019ll proceed to dunk at his opponents with tremendous skills. Beware\u2026 Or feel the heat, as those are sure to drain the Boo energy out of you!"
              },
              {
                  "number": 3,
                  "name": "MAGMA PUNCH (unused in episode)",
                  "text": "In its Booster mode, DUNKY BOSS can ignite its fists to deliver powerful fiery punches that are sure to set its opponents ablaze\u2026 And to send them in the ropes!"
              },
              {
                  "number": 4,
                  "name": "HEAT WAVE (unused in episode)",
                  "text": "In its Booster mode, DUNKY BOSS can release part of its body heat into a fire wave that emanates from itself and calcinates everything surrounding it."
              }
          ],
          "defenses": [
              {
                  "number": 1,
                  "name": "BOUNCY SKIN",
                  "text": "In its booster mode, DUNKY BOSS skin becomes so stretchy\u2026 That most attacks will bounce back off it and be sent back to the attacker! So watch out, or you might get hit by your own punches."
              },
              {
                  "number": 2,
                  "name": "MAGMA FLARE (unused in episode)",
                  "text": "In its booster mode, DUNKY BOSS can illuminate itself from the inside out to emit a powerful magmatic flare that can temporarily blind its opponents."
              },
              {
                  "number": 3,
                  "name": "MAGMA COCOONS",
                  "text": "As it finally gets sated with boo energy, DUNKY BOSS can unleash it in the form of a multitude of magmatic cocoons that sprout from its spike and are propelled everywhere around it. Inside those cocoons, an army of newborn BOUNCY start to grow\u2026 Menacing to unleash a new generation of ghost onto the world if the original one isn\u2019t stopped in time !"
              }
          ]
      }
  },
  {
      "name": "Dinozos",
      "image": {
          "png": require("../Assets/Ghosts/lineup/Dinozos.png").default,
          "webp": require("../Assets/Ghosts/lineup/Dinozos.webp").default,
          "width": 383,
          "height": 291
      },
      "card": require("../Assets/Ghosts/Cards/SPECTRAL DINOZOS.jpg").default,
      "infos": {
          "kawaii name": "Torsand",
          "kaiju name": "Dinozos",
          "level": "5",
          "family": "Spectral",
          "types": "Summoner / Bone",
          "nest": "An ancient tyrannosaurus fossil",
          "receptacle": "None",
          "age": "Awe",
          "rarity": "Rare",
          "deplacement": "Levitating in Kawa\u00ef mode\u2026 But only able to walk in its Booster mode!",
          "Weakness": "DINOZOS\u2019 might reside within its overpowered jaw\u2026 Making it its weak spot as well! Find a way to keep it shut for good and this very scary ghost should become a very easy catch.",
          "": "",
          "attacks": [
              {
                  "number": 1,
                  "name": "JURRASIC CHARGE",
                  "text": "In its Kawa\u00ef mode, DINOZOS can spread its DINOCURSE to anyone it passes through, turning them into floating fossilized dino skulls that try to bite anyone they see. Spooky\u2026 And snappy!"
              },
              {
                  "number": 2,
                  "name": "JURRASIC JAWS",
                  "text": "In its booster mode, DINOZOS becomes able to spread its DINOCURSE to anyone it bites with its overly mighty jaws. What\u2019s more, its bitting power is so potent, it can chew through the defenses of most opponents\u2026 Even trhough the shields of the Ghost Car!"
              }
          ],
          "defenses": [
              {
                  "number": 1,
                  "name": "DINOCURSE",
                  "text": "In both its Kawa\u00ef and Booster mode, DINOZOS\u2019s victims are all stricken with its DINOCURSE, that turns them into spooky fossilized dino skulls that floats around, snapping at everyone like crazed raptors, as they try to spread their curse by biting the maximum number of people."
              },
              {
                  "number": 2,
                  "name": "BOO CARNIVORUS REX",
                  "text": "Where most Ghosts have to feed on fear to power up their attacks, DINOZOS is also able to convert carnivorous protein into Boo energy. This allows it to power up\u2026 Just by eating meat! The more rotten it is, the tastier it finds it."
              }
          ]
      }
  },
  {
      "name": "Gmagicard",
      "image": {
          "png": require("../Assets/Ghosts/lineup/GMAGICARD.png").default,
          "webp": require("../Assets/Ghosts/lineup/GMAGICARD.webp").default,
          "width": 383,
          "height": 291
      },
      "card": require("../Assets/Ghosts/Cards/STRANGE GMAGICARD.jpg").default,
      "infos": {
          "kawaii name": "Gmagia",
          "kaiju name": "Gmagicard",
          "level": "5",
          "family": "Strange",
          "types": "Magic / Merger",
          "nest": "Roadway theatre sign",
          "receptacle": "A set of magic cards",
          "age": "Awe",
          "rarity": "Rare",
          "deplacement": "Levitating",
          "Weakness": "As GMAGICARD powers originate from its hat and the cards it keeps within it, you\u2019ll have to neutralize it in order to defeat it. Either by knocking it out of its head\u2026 Or just by locking it shut to stop GMAGICARD from drawing cards from it!",
          "": "",
          "attacks": [
              {
                  "number": 1,
                  "name": "GMAGIC TRICKS",
                  "text": "In its Kawa\u00ef form, GMAGIA can turn anyone it passes through into magic props. Take care to not let it get to you, or you might end up as a stuffed toy rabbit, a flower bouquet or even a pair of dices!"
              },
              {
                  "number": 2,
                  "name": "GMAGIC CARDS",
                  "text": "In its Booster mode, GMAGICARD can shoots a barrage of giant magic cards from its hat, turning anyone they hits into magic props. The exact nature of the props is based upon the color of the card: Stuffed rabbits for hearths, balloons for spade, dices for clubs and flower bouquets for diamonds!"
              }
          ],
          "defenses": [
              {
                  "number": 1,
                  "name": "CARDS WEAPONNERY",
                  "text": "In booster mode, GMAGICARD can draw cards from its deck to fashion them into any sort of card-based weaponry. Card-swords, card-battleaxes and card-shields are all great option for GMAGICARD to draw against its foes."
              },
              {
                  "number": 2,
                  "name": "UNKNOWN",
                  "text": "(Unused in episode)"
              }
          ]
      }
  },
  {
      "name": "Graffurious",
      "image": {
          "png": require("../Assets/Ghosts/lineup/GRAFFURIOUS.png").default,
          "webp": require("../Assets/Ghosts/lineup/GRAFFURIOUS.webp").default,
          "width": 383,
          "height": 291
      },
      "card": require("../Assets/Ghosts/Cards/SPECTRAL GRAFFURIOUS.jpg").default,
      "infos": {
          "kawaii name": "Breathrill",
          "kaiju name": "Graffurious",
          "level": "5",
          "family": "Spectral",
          "types": "Wind / Merger",
          "nest": "An old electric fan",
          "receptacle": "Spray paint can",
          "age": "Awe",
          "rarity": "Uncommon",
          "deplacement": "Levitation in kawa\u00ef mode. Flight in booster mode, accelerating itself by using its spray of paint like jetpacks.",
          "Weakness": "As GRAFFURIOUS relies on the colored gust of paint from its valves to defend itself, it can be rendered completely harmless by plugging all three of those at the same time\u2026 Making it green with envy and red with rage as you easily capture it!",
          "": "",
          "attacks": [
              {
                  "number": 1,
                  "name": "GUSTY BREATH",
                  "text": "In its Kawai mode, BREATHRILL can blow a powerful gust of spectral air from the fan on its head. Anything that stands in its way, be it objects or people, are thrown backwards by this puff."
              },
              {
                  "number": 2,
                  "name": "COLOR BLAST",
                  "text": "In its Booster mode, GRAFFURIOUS can shoot mighty jets of paint from the three valves of its body: the one on its head and the two at the end of its arms. The gusts coming from those  will not only knock anything away from the ghost, but the pigments in it will also quickly turn its targets \u2013 and their surrounding - into a patchwork of primary colors!"
              }
          ],
          "defenses": [
              {
                  "number": 1,
                  "name": "COLOR SPIN",
                  "text": "In its Booster mode GRAFFURIOUS can spins around at high speed to spray its paints at 360 degrees. The resulting colored cloud of paint prevents anyone from targeting it\u2026 And allows for the ghost to make its get-away!"
              },
              {
                  "number": 2,
                  "name": "COLOR CHAMELEON: (unused in episode)",
                  "text": "In its Booster mode, GRAFFURIOUS can paint itself in various color to blend in with its surrounding\u2026 Which it usually painted over itself beforehand! (E.g.: it will tag a wall neon yellow and becomes neon yellow itself to 'camouflage' itself in front of it.)"
              }
          ]
      }
  },
  {
      "name": "Gumglue",
      "image": {
          "png": require("../Assets/Ghosts/lineup/GUMGLUE.png").default,
          "webp": require("../Assets/Ghosts/lineup/GUMGLUE.webp").default,
          "width": 383,
          "height": 291
      },
      "card": require("../Assets/Ghosts/Cards/VISCOUS GUMGLUE.jpg").default,
      "infos": {
          "kawaii name": "Gummy",
          "kaiju name": "Gumglue",
          "level": "6",
          "family": "Viscous",
          "types": "Sticky / Merger",
          "nest": "A roll of tape",
          "receptacle": "A tube of glue",
          "age": "Horror",
          "rarity": "Uncommon",
          "deplacement": "Levitation",
          "Weakness": "As GUMGLUE\u2019s powers originate from its glue stick arms and its glue stick body, locking those down should stop it from being able to attack anyone\u2026 Making it an easy capture!",
          "": "",
          "attacks": [
              {
                  "number": 1,
                  "name": "STICKY CHARGE",
                  "text": "In its Kawa\u00ef mode, GUMMY can stick anyone its passes through to whatever they\u2019re touching at the time. Its victim suddenly find themselves stuck with viscous glue at their hands, feet, heads or butts, depending on the part of their anatomy that where in contact with solids as the Ghost passed through them."
              },
              {
                  "number": 2,
                  "name": "STICKY BLAST",
                  "text": "In its Booster mode, GUMGLUE can shoot massive projectiles of Viscous Glue from its glue stick hands, that\u2019ll entrapped in glue anyone or anything they\u2019d hit."
              },
              {
                  "number": 3,
                  "name": "GLUE STICK",
                  "text": "In its Booster mode, GUMGLUE can extends the glue stick from the tube it merged with. Anyone, or anything, it touches gets suddenly transformed into glue! Yeah, you read that right. This ability doesn\u2019t encase them in glue like the others\u2026 It MAKES them glue! Spooky."
              }
          ],
          "defenses": [
              {
                  "number": 1,
                  "name": "GLUE SHIELD (unused in episode)",
                  "text": "In its booster mode, GUMGLUE can extend the glue sticks from its arms and mold them like shields to deflect incoming attacks."
              },
              {
                  "number": 2,
                  "name": "GLUE DODGE",
                  "text": "In its booster mode, GUMGLUE can quickly retract the glue stick from its body to make itself a smaller, much harder to hit target\u2026 Allowing it to dodge incoming attacks."
              }
          ]
      }
  },
  {
      "name": "Hypnolion",
      "image": {
          "png": require("../Assets/Ghosts/lineup/HYPNOLION.png").default,
          "webp": require("../Assets/Ghosts/lineup/HYPNOLION.webp").default,
          "width": 383,
          "height": 291
      },
      "card": require("../Assets/Ghosts/Cards/SPECTRAL HYPNOLION.jpg").default,
      "infos": {
          "kawaii name": "Flamagma",
          "kaiju name": "Hypnolion",
          "level": "7",
          "family": "Spectral",
          "types": "Shrieker / Hypnotic / Metal",
          "nest": "Circus whip",
          "receptacle": "None",
          "age": "Panic",
          "rarity": "Uncommon",
          "deplacement": "Levitating in Kawa\u00ef mode\u2026 And both flying and pouncing around like the big spectral it is!",
          "Weakness": "Despite appearances, HYPNOLION\u2019s howler type and its habits to roar loudly, its powers don\u2019t actually originate from its mouth\u2026 But from its paws! So make sure not to miss that crucial detail and aim for the big kitty\u2019s pads!",
          "": "",
          "attacks": [
              {
                  "number": 1,
                  "name": "ANIMA CHARGE",
                  "text": "In its Kawa\u00ef mode, HYPNOLION can hypnotize anyone it passes through\u2026 Suddenly making them believe they\u2019re savannah animals! Its victims will start growling and acting like beast of various sorts\u2026 Quickly turning the city in a literal urban jungle!"
              },
              {
                  "number": 2,
                  "name": "HYPNOROAR",
                  "text": "In its Booster mode, HYPNOLION can let out an hypnotic shockwave by slamming its two front paws to the ground. This shockwave will act just like its ANIMA CHARGE ability, hypnotizing anyone it hits in believing they are savannah animals. HYPNOLION usually telegraphs this ability by letting out a mighty lion roar at the same time... But this roar doesn\u2019t actually affect the power in any way. HYPNOLION just thinks it makes it look cooler!"
              },
              {
                  "number": 3,
                  "name": "LION CLAWS (unusued in episode)",
                  "text": "In its Booster mode, HYPNOLION becomes able to extend and retract terrifyingly huge lion\u2019s claws! Those are so sharp, they can cut through any sort of shields... Even Boo energized ones!"
              }
          ],
          "defenses": [
              {
                  "number": 1,
                  "name": "METAL MANE",
                  "text": "In its Booster mode, HYPNOLION\u2019s metal mane covers its entire body, rendering it extremely resilient to any sort of attacks."
              },
              {
                  "number": 2,
                  "name": "TAIL SPIN",
                  "text": "In its Booster mode, whenever it feels to threaten by anyone standing too close for comfort, HYPNOLION can deliver impressive tail spinning kicks, that knock away and stun their targets."
              }
          ]
      }
  },
  {
      "name": "Jellyjack",
      "image": {
          "png": require("../Assets/Ghosts/lineup/JELLYJACK.png").default,
          "webp": require("../Assets/Ghosts/lineup/JELLYJACK.webp").default,
          "width": 383,
          "height": 291
      },
      "card": require("../Assets/Ghosts/Cards/VISCOUS JELLYJACK.jpg").default,
      "infos": {
          "kawaii name": "Glups",
          "kaiju name": "Jellyjack",
          "level": "8",
          "family": "Viscous",
          "types": "Toxic / Shape-Shifter / Merger",
          "nest": "None",
          "receptacle": "A knightly armor",
          "age": "Terror",
          "rarity": "Unique",
          "deplacement": "Levitating in Kawa\u00ef mode\u2026 And walking around heavily in its knightly booster mode.",
          "Weakness": "The lovable Glups is an adorably friendly ghost\u2026 With a severe solar allergy, that sends it back to its wild state and into a blind destructive rage  whenever expose to UV! While this means it will go on a rampage whenever facing the sun, it also happens to be the best way to defeat it once in enters that unstoppable raging state\u2026 On the condition you can find the gaps through all of his knightly defenses!",
          "": "",
          "attacks": [
              {
                  "number": 1,
                  "name": "JELLY SPIT",
                  "text": "In its Kawa\u00ef mode, GLUPS can spit out projectile of Viscous Boo, that\u2019ll entrap anyone they hit in a case of colored jelly! And while Glups is usually a fun lil\u2019 guy who wouldn\u2019t spit on a fly, it\u2019s solar allergy makes can makes it very aggressive and\u2026 Well, spitty."
              },
              {
                  "number": 2,
                  "name": "EXCAJELLYBUR",
                  "text": "In its Booster mode, JELLYJACK can shape-shift its Jelly in the form of a massive knightly blade that it uses with spooky dexterity. Beware the blade of EXCAJELLYBUR , or get ready for a smackdown! Only one shape-sifting power can be active at a time."
              },
              {
                  "number": 3,
                  "name": "MORGENJELLY",
                  "text": "In its Booster mode, JELLYJACK can shape-shift its Jelly to form a massive knightly Morgenstern that can extends for miles and miles, chasing after its foes. Beware the MORGENJELLY or get ready for a pummeling! Only one shape-sifting power can be active at a time."
              },
              {
                  "number": 4,
                  "name": "JELLY PLUNGE",
                  "text": "In its Booster mode, while its jelly is shape-shifted into its EXCAJELLYBUR form, JELLYJACK can seize it with both hands to applicate it JELLY HAND effect of the blade\u2026 Converting anything it plunges its sword in into jelly! That effect is so potent, it can convert entire streets, skyscraper or even the statue of liberty into Jelly!"
              }
          ],
          "defenses": [
              {
                  "number": 1,
                  "name": "JELLY SHIELD",
                  "text": "In its Booster mode, JELLYJACK can shape-shift its Jelly to form a massive knightly shield that can deflect any sort of attacks. Beware the JELLY SHIELD, for it is as spooky as it is impervious! Only one shape-sifting power can be active at a time."
              },
              {
                  "number": 2,
                  "name": "JELLY KNIGHT ARMOR",
                  "text": "In its Booster mode, having merged with an authentic knightly armor, JELLY JACK enjoys an almost impregnable defense. But beware, for behind the armor rest a hearth as soft as jelly\u2026 Or, well, at least when it\u2019s not victim to its allergy."
              },
              {
                  "number": 3,
                  "name": "JELLY HAND",
                  "text": "In its Booster mode, JELLYJACK only unarmored hand keeps the Jellifying properties of its JELLY SPIT ability. And while he can\u2019t affect people with it from a distance anymore, its power are multiplied in Booster mode. Beware\u2026 Or taste the Jelly!"
              }
          ]
      }
  },
  {
      "name": "Jinjoke",
      "image": {
          "png": require("../Assets/Ghosts/lineup/JINJOKE.png").default,
          "webp": require("../Assets/Ghosts/lineup/JINJOKE.webp").default,
          "width": 383,
          "height": 291
      },
      "card": require("../Assets/Ghosts/Cards/SPECTRAL JINJOKE.jpg").default,
      "infos": {
          "kawaii name": "Spong",
          "kaiju name": "Jinjoke",
          "level": "4",
          "family": "Spectral",
          "types": "Prankster / Illusionist",
          "nest": "A whoopee cushion",
          "receptacle": "None",
          "age": "Dread",
          "rarity": "Uncommon",
          "deplacement": "Flight and levitation",
          "Weakness": "As JINJOKE\u2019s illusions come from its nose, all that\u2019s needed to vanquish it is to hit it there. A task easier said than done, due to all the illusions JINJOKE uses to protect itself!",
          "": "",
          "attacks": [
              {
                  "number": 1,
                  "name": "SPECTRAL JOKE",
                  "text": "In both its Kawa\u00ef and Booster mode, JINJOKE can fabricate illusions that it\u2019ll uses to \u201cprank\u201d people with\u2026 Though, due to his ghastly nature, JINJOKE\u2019s pranks tend to be quite scary in nature, its illusions luckily have no tangible effect and can be dissipated by any kind of boo energy attack."
              },
              {
                  "number": 2,
                  "name": "ECTOWHIP",
                  "text": "In booster mode, JINJOKE can use his tail as a powerful whip, knocking its opponents away and draining some of their boo energy in the process."
              }
          ],
          "defenses": [
              {
                  "number": 1,
                  "name": "ON THE NOSE",
                  "text": "Once it has reach a sufficient amount of Boo energy, JINJOKE can activate this defensive ability, turning its nose red\u2026 And its illusions tangible! This makes him into a very dangerous adversary, as they start having a real impact and become impossible to dissipate."
              }
          ]
      }
  },
  {
      "name": "Kaboom",
      "image": {
          "png": require("../Assets/Ghosts/lineup/KABOOM.png").default,
          "webp": require("../Assets/Ghosts/lineup/KABOOM.webp").default,
          "width": 383,
          "height": 291
      },
      "card": require("../Assets/Ghosts/Cards/SPECTRAL KABOOM.jpg").default,
      "infos": {
          "kawaii name": "Torsand",
          "kaiju name": "Kaboom",
          "level": "7",
          "family": "Spectral",
          "types": "Wind / Explosive / Prankster",
          "nest": "Firecracker",
          "receptacle": "None",
          "age": "Panic",
          "rarity": "Very rare",
          "deplacement": "Levitating in Kawa\u00ef and Booster mode. Propelling itself around with its wind pipes arms in Booster mode.",
          "Weakness": "Surprisingly enough, KABOOM can\u2019t stop its explosions once it has manifested a fuse\u2026 And it isn\u2019t immune to its own abilities either ! So if you can find a way to keep it immobilized and its explosion contained, that ghost is sure to take itself out with BOOM!",
          "": "",
          "attacks": [
              {
                  "number": 1,
                  "name": "EXPLOFEAR",
                  "text": "In its Kawa\u00ef mode, KABOOM can make things around it explodes just by focusing its Boo energy on it! While this ability isn\u2019t powerful enough to cause any real actual damage\u2026 It sure is enough to allow KABOOM to sow chaos, fear and confusion all around it!"
              },
              {
                  "number": 2,
                  "name": "EXPLOCLOCK",
                  "text": "In its Booster mode, KABOOM can manifest a spectral fuse on its head, that\u2019ll start quickly burning itself out, before releasing an explosive spectral blast when it finishes. The exact nature of this spectral blast will depends on the color of KABOOM\u2019s fuse :\n-\tBLUE : The blue fuse ends with a spectral explosion of confetti\u2019s\u2026 That don\u2019t do anything at all! Yeah. It\u2019s a prankster ghost!\n-\tGREEN : The green fuse ends with a gong-ringing spectral shockwave that\u2019ll stun anyone caught in it and will make them shake uncontrollably for hours afterward\u2026 Just like if they had been hit like a gong!\n-\tPINK : The pink fuse ends with a gaseous explosion that releases a thick pink cloud of itching powder! Anyone caught in it will starts itching and scratching uncontrollably, unable to ever stop."
              },
              {
                  "number": 3,
                  "name": "MEGAXPLOCLOCK",
                  "text": "In its Booster mode, once it has ingested enough fear, KABOOM can convoke a final fuse on its head that combines all three version of its EXPLOCLOCK ability into one. Once the merged blue, green and pink fuse finishes, KABOOM releases an explosion of confetti\u2019s, itching powder and shockwave that is sure to leave anyone incapacited."
              }
          ],
          "defenses": [
              {
                  "number": 1,
                  "name": "WINDPIPES BLOW",
                  "text": "In its Booster mode, KABOOM\u2019s wing blowing arms can blow powerful gusts of air that allow it to push away anyone coming too close for comfort. Back off!"
              },
              {
                  "number": 2,
                  "name": "WINDPIPES DASH",
                  "text": "In its Booster mode, KABOOM\u2019s wind blowing arms allows it to dash by releasing quick burst of air from it. That makes it surprisingly agile\u2026 And very good at dodging!"
              }
          ]
      }
  },
  {
      "name": "Levisfer",
      "image": {
          "png": require("../Assets/Ghosts/lineup/LEVISFER.png").default,
          "webp": require("../Assets/Ghosts/lineup/LEVISFER.webp").default,
          "width": 383,
          "height": 291
      },
      "card": require("../Assets/Ghosts/Cards/MOLECULAR LEVISFER.jpg").default,
      "infos": {
          "kawaii name": "Gummy",
          "kaiju name": "Levisfer",
          "level": "8",
          "family": "Molecular",
          "types": "Chaotic / Electric / Telekinetic",
          "nest": "A bowling bowl",
          "receptacle": "None",
          "age": "Terror",
          "rarity": "Rare",
          "deplacement": "Levitation. It floats and spins around.",
          "Weakness": "As a anti-gravity ghost, LEVISFER relies on its own weightless properties to be successful. As such, it is very susceptible to anything that would make it heavier. Making some kind of weight-modifying Boo energy gadget the best way to capture it.",
          "": "",
          "attacks": [
              {
                  "number": 1,
                  "name": "GRAVILESS",
                  "text": "In its Kawa\u00ef mode, LEVISFER can uses its telekinetic powers to cancel the effect of gravity on medium sized objects and peoples\u2026 Making them weightless and sending them in a levitating state! This power originates from the intertwined spheres surrounding its face."
              },
              {
                  "number": 2,
                  "name": "SPINBALL ATTACK",
                  "text": "In its Booster mode, LEVISFER can split it body into a multitude of spheres that it can then independently swing around, like a pinball-machine\u2026 Creating a veritable storm of blows that is sure to knock away anyone standing nearby!"
              },
              {
                  "number": 3,
                  "name": "GRAVITAX",
                  "text": "In its Booster mode, by splitting its body and making it spin on itself at great speed, LEVISFER can create \u201cAnti-Gravity Zones\u201d. Everything - and everyone \u2013 standing in those zones will then see its weight reduces to zero and will start to float around helplessly !"
              },
              {
                  "number": 4,
                  "name": "GRAVIMAX",
                  "text": "In booster mode, once it has reached sufficient Boo energy level, LEVISFER can activate a bigger and even stronger version of its GRAVITAX ability, Making entire places, neighborhood or even city lose their weight, tearing them from the earth and sending them in an anti-gravitational trip to space!"
              }
          ],
          "defenses": [
              {
                  "number": 1,
                  "name": "SPINBALL SHIELD",
                  "text": "In its booster mode, LEVISFER can spin the detachable spheres of its arms at great speed around itself, creating an effective kinetic shield that protects it from most attacks."
              },
              {
                  "number": 2,
                  "name": "SPINING SPLIT",
                  "text": "In his booster mode, LEVISFER can split and spread away the detachable spheres of its arms to make itself a smaller \u2013 and much harder to hit \u2013 target, effectively increasing its dodging capabilities."
              }
          ]
      }
  },
  {
      "name": "Mascarade",
      "image": {
          "png": require("../Assets/Ghosts/lineup/MASCARADE.png").default,
          "webp": require("../Assets/Ghosts/lineup/MASCARADE.webp").default,
          "width": 383,
          "height": 291
      },
      "card": require("../Assets/Ghosts/Cards/STRANGE MASCARADE.jpg").default,
      "infos": {
          "kawaii name": "Petrifear",
          "kaiju name": "Mascarade",
          "level": "7",
          "family": "Strange",
          "types": "Chaotic / Hypnotic / Triplet",
          "nest": "Old theatre mask",
          "receptacle": "None",
          "age": "Panic",
          "rarity": "Unique",
          "deplacement": "Levitating.",
          "Weakness": "Due to the inordinately resistant nature of its body, it\u2019d be foolish to attack Mascarade head on and hope to succeed. Instead, try and aim at the source of its powers and only fragile part of the ghosts : the Masks it wears so proudlys",
          "": "",
          "attacks": [
              {
                  "number": 1,
                  "name": "ECTO RICTUS",
                  "text": "In its Kawa\u00ef mode, the three masked ghosts that form MASCARADE are able to change the mood of anyone they passes through. The mood imposed on their victim depends on the ghosts that passes them. The one wearing a sad Mask makes peoples sad, the one with the joyful Mask makes them laughs and the one with the angry Mask makes them\u2026 You guessed it: furious!"
              },
              {
                  "number": 2,
                  "name": "MASK MISSILE",
                  "text": "In its Booster mode, once the three masked ghost that form MASCARADE have fused into one, it becomes able to shoot mask-like projectiles out of its worn mask, that change the mood of anyone they hit!"
              },
              {
                  "number": 3,
                  "name": "MASCASLAP",
                  "text": "In its Booster mode, MASCARADE can use the indestructible properties of its body to deliver crushing hand blows that are sure to leave you groggy. So take care not to get hit\u2026 As those are not theatrics!"
              }
          ],
          "defenses": [
              {
                  "number": 1,
                  "name": "ECTO BODY",
                  "text": "In booster mode, the Boo energy that constitutes MASCARADE body is especially hard. Making it a truly resilient ghost that can withstand crazy amount of damage."
              },
              {
                  "number": 2,
                  "name": "MASK SWAP",
                  "text": "In his booster mode, MASCARADE can quickly swap its Masks to change the mood imposed by its MASK MISSILE ability."
              }
          ]
      }
  },
  {
      "name": "Meta & Lix",
      "image": {
          "png": require("../Assets/Ghosts/lineup/Meta & Lix.png").default,
          "webp": require("../Assets/Ghosts/lineup/Meta & Lix.webp").default,
          "width": 383,
          "height": 291
      },
      "card": require("../Assets/Ghosts/Cards/MOLECULAR META & LIX.jpg").default,
      "infos": {
          "kawaii name": "Flame",
          "kaiju name": "Meta & Lix",
          "level": "7",
          "family": "Molecular",
          "types": "Metal / Electric / Twins",
          "nest": "An old metal spiked boot",
          "receptacle": "None",
          "age": "Panic",
          "rarity": "Rare",
          "deplacement": "Levitation in both Kawa\u00ef en Booster mode.",
          "Weakness": "Magnetism \u2013 and team work \u2013 being keys to META & LIX strategies, the best way to defeat them is to find a way to disrupt their magnetic compatibility. Either by generating a competing magnetic field\u2026 Or by sowing mistrust in their relationship!",
          "": "",
          "attacks": [
              {
                  "number": 1,
                  "name": "METATTRACTION",
                  "text": "In its Kawa\u00ef mode, META can act as a magnet that attract both metals and Boo energy towards it. A power that would be fairly limited in itself\u2026 If META were not acting as a pair with LIX\u2019s!"
              },
              {
                  "number": 2,
                  "name": "REPELIX",
                  "text": "In its Kawa\u00ef mode, LIX can act as a magnet that repels both metals and Boo energy away from it. A power that would be fairly limited in itself\u2026 If LIX were not acting as a pair with META\u2019s!"
              },
              {
                  "number": 3,
                  "name": "MAX MAGNET",
                  "text": "In their Booster mode, once they have fused together into one single entity, META & LIX become able to move metal and Boo energy around at a whim! Pushing, pulling, moving, swinging, shoving and others\u2026 Their imagination is the limit to what they can accomplish with that ability!"
              }
          ],
          "defenses": [
              {
                  "number": 1,
                  "name": "MAGNETIC SHIELD",
                  "text": "In their Booster mode, META & LIX are surrounded by a magnetic field that affect both metals and Boo energy alike\u2026 And act as a defensive shield from incoming attacks involving those elements!"
              },
              {
                  "number": 2,
                  "name": "MAGNETIC STUN :  (unused in episode)",
                  "text": "In their Booster mode, META & LIX can use their magnetic properties to generate a short range and low intensity electricity burst that will temporarily stun any foes that standing near them, buying the pair some time to prepare another attack\u2026 Or just to run away!"
              }
          ]
      }
  },
  {
      "name": "Ninjaki",
      "image": {
          "png": require("../Assets/Ghosts/lineup/NINJAKI.png").default,
          "webp": require("../Assets/Ghosts/lineup/NINJAKI.webp").default,
          "width": 383,
          "height": 291
      },
      "card": require("../Assets/Ghosts/Cards/MOLECULAR NINJAKI.jpg").default,
      "infos": {
          "kawaii name": "Droplet",
          "kaiju name": "Ninja Ki",
          "level": "4",
          "family": "Molecular",
          "types": "Furtive / Telekinetic",
          "nest": "Japanese prints",
          "receptacle": "None",
          "age": "Dread",
          "rarity": "Uncommon",
          "deplacement": "Flight and levitation.",
          "Weakness": "As NINJAKI\u2019s power of invisibility makes it very hard to hit, the best way to defeat it is to force it to show itself\u2026 By opening yourself to one of its attack!",
          "": "",
          "attacks": [
              {
                  "number": 1,
                  "name": "TELE-KI-NESY",
                  "text": "In both its Kawa\u00ef and Booster mode, NINJAKI telepathic powers allows it to move things around with its mind. While it can also use this ability while visible, NINJAKI prefers to do it in combination with its KINVISIBILITY ability, in order to create the spooky impression of an haunted house!"
              },
              {
                  "number": 2,
                  "name": "HACHIMA-KI",
                  "text": "In its Booster mode, NINJAKI\u2019s headbands turn into dangerous weapons it can whip and slash around at great speed. Any hit from this ability will drain the Boo energy from NINJAKI\u2019s targets."
              }
          ],
          "defenses": [
              {
                  "number": 1,
                  "name": "KINVISIBILITY",
                  "text": "In both its Kawa\u00ef and Booster mode, NINJAKI has the power to turn itself completely invisible\u2026 So good luck catching that one! Fortunately, attacking or using its HACHIMA-KI ability causes it to become visible again for a few second."
              }
          ]
      }
  },
  {
      "name": "Paniclick",
      "image": {
          "png": require("../Assets/Ghosts/lineup/PANICLICK.png").default,
          "webp": require("../Assets/Ghosts/lineup/PANICLICK.webp").default,
          "width": 383,
          "height": 291
      },
      "card": require("../Assets/Ghosts/Cards/FRACTAL PANICLICK.jpg").default,
      "infos": {
          "kawaii name": "Flip Flat",
          "kaiju name": "Paniclick",
          "level": "4",
          "family": "Fractal",
          "types": "Chaotic / Merger",
          "nest": "An 80\u2019s movie poster",
          "receptacle": "A polaroid camera",
          "age": "Dread",
          "rarity": "Rare",
          "deplacement": "Levitation in kawa\u00ef mode. Walking and floating around in booster mode.",
          "Weakness": "As PANICLICK relies on the flash of its camera to keep itself safe, deactivating it is the first step in handling that pesky photographer. The second and final one being to shut him off for good\u2026 By shutting off the blind of its camera!",
          "": "",
          "attacks": [
              {
                  "number": 1,
                  "name": "FLAT ATTACK",
                  "text": "In its Kawa\u00ef form, FLIP FLAT can \u00ab posterize \u00bb anyone it goes through... Effectively flattening its victims and turning them into 2D version of themselves!"
              },
              {
                  "number": 2,
                  "name": "PHOTO BOMB",
                  "text": "In its booster mode, PANICLICK can produce a cone of light from its camera that\u2019ll suck in the people it aims it at, before releasing them back transformed into giant photography of themselves. Beware, and make sure to dodge this one... Or get ready to say cheese!"
              }
          ],
          "defenses": [
              {
                  "number": 1,
                  "name": "PHOTO FLASH",
                  "text": "By turning on the flash of the camera it has merged with, PANICLICK can release a sudden burst of light that is sure to blind its foes, making them more vulnerable to its own attack and much less likely to hit back."
              }
          ]
      }
  },
  {
      "name": "Piraniak",
      "image": {
          "png": require("../Assets/Ghosts/lineup/PIRANIAK.png").default,
          "webp": require("../Assets/Ghosts/lineup/PIRANIAK.webp").default,
          "width": 383,
          "height": 291
      },
      "card": require("../Assets/Ghosts/Cards/STRANGE PIRANIAK.jpg").default,
      "infos": {
          "kawaii name": "Terrorice",
          "kaiju name": "Piraniak",
          "level": "7",
          "family": "Strange",
          "types": "Water / Hypnotist / Collector",
          "nest": "An ancient pirate chest",
          "receptacle": "None",
          "age": "Panic",
          "rarity": "Very rare",
          "deplacement": "Levitating in Kawa\u00ef mode, \u201cswimming\u201d around like a snake in Booster mode.",
          "Weakness": "As a greedy collector ghost, PIRANIAK is obsessed with anything made out of gold, which it also uses to power its most powerful ability\u2019s\u2026 Fortunately for anyone trying to capture it, it\u2019s unable to digest any other metal with its GOLD RUSH ability\u2026 And will suffer from a crippling indigestion if it ever does swallow any!",
          "": "",
          "attacks": [
              {
                  "number": 1,
                  "name": "HYPNO FLASH",
                  "text": "In its Kawa\u00ef mode, PIRANIAK can mesmerized people by illuminating its body with a golden glow and flashing it at them. Its victims, completely enthralled will then starts accumulating quantities of gold and golden item for their new master\u2026 While fervently fighting anyone opposing it!"
              },
              {
                  "number": 2,
                  "name": "HYPNO CHEST",
                  "text": "In its Booster mode, once the chest on its body as been filed with enough gold thanks to its GOLD RUSH ability, PIRANIAK can then empty it in a explosion of ghastly doubloons\u2026 And anyone catching sight of those spooky ghost coins will then fall victim to the hypnotic powers of the Ghost!"
              },
              {
                  "number": 3,
                  "name": "HOOK ATTACK (unused in episode)",
                  "text": "In its Booster mode, PIRANIAK\u2019s tail get adorned with an impressive Pirate Hook\u2026 That it can use as an offensive weapon against its foes!"
              }
          ],
          "defenses": [
              {
                  "number": 1,
                  "name": "GOLD RUSH",
                  "text": "Where most Ghosts have to feed on fear to power up their attacks, PIRANIAK is also able to convert gold into Boo energy\u2026 By sucking it into its body-chest! This allows it to power its ability by having its victims gather gold for him via its HYPNO FLASH and HYPNO CHEST abilities."
              },
              {
                  "number": 2,
                  "name": "COIL DODGE",
                  "text": "In its booster mode, PIRANIAK snake-like nature allows it to skillfully dodge incoming attack by coiling its serpentine body around\u2026 Making it much harder to hit than most other ghosts!"
              }
          ]
      }
  },
  {
      "name": "Prehistorrible",
      "image": {
          "png": require("../Assets/Ghosts/lineup/PREHISTORRIBLE.png").default,
          "webp": require("../Assets/Ghosts/lineup/PREHISTORRIBLE.webp").default,
          "width": 383,
          "height": 291
      },
      "card": require("../Assets/Ghosts/Cards/SPECTRAL PREHISTORRIBLE.jpg").default,
      "infos": {
          "kawaii name": "Terrorice",
          "kaiju name": "Prehistorrible",
          "level": "7",
          "family": "Spectral",
          "types": "Electric / Ice / Merger",
          "nest": "A prehistoric mammoth tusk",
          "receptacle": "An NYC subway train",
          "age": "Panic",
          "rarity": "Rare",
          "deplacement": "Levitation in Kawa\u00ef mode. Floating and driving around in Booster mode.",
          "Weakness": "While it may be its most powerful weapon, PREHISTORRIBLE\u2019s trunk is also its weakest spot. Making the act of tying it up the best way to defeat and capture this ghost\u2026 If only it were easier to reach it",
          "": "",
          "attacks": [
              {
                  "number": 1,
                  "name": "ICY BREATH",
                  "text": "In its Kawa\u00ef mode, PREHISTORRIBLE can blow an icy blast of air from its trunk, that\u2019ll trap anyone caught in it in giant icicles! Creepy\u2026 And cold. Luckily, a strong enough heat source is enough to revert the ghost\u2019s effect and brings you back to room temperature."
              },
              {
                  "number": 2,
                  "name": "ELECTROTUSK (unused in episode)",
                  "text": "In its booster mode, PREHISTORRIBLE\u2019s tusks becomes electrified, allowing it to shoot thunderbolt from it. Electrifying!"
              },
              {
                  "number": 3,
                  "name": "PREHISTOCHARGE",
                  "text": "In its booster mode, PREHISTORRIBLE can send itself full speed ahead into a mighty charge, while blowing an icy wind from its trunk. This devastating attack will freeze everything \u2013 and everyone - in its path, while also knocking anything Boo related out of his way like it were bowling pins!"
              }
          ],
          "defenses": [
              {
                  "number": 1,
                  "name": "ELECTROSHIELD (unused in episode)",
                  "text": "In booster mode, PREHISTORRIBLE can generate an field of electricity between its tusk, allowing it to stun anyone coming to close to it for a few second."
              },
              {
                  "number": 2,
                  "name": "ICE WALL (unused in episode)",
                  "text": "In its booster mode, PREHISTORRIBLE can use its icy breath to quickly create a wall of ice just in front of itself\u2026 Allowing it to protect itself against incoming attacks."
              }
          ]
      }
  },
  {
      "name": "Scaregrow",
      "image": {
          "png": require("../Assets/Ghosts/lineup/SCAREGROW.png").default,
          "webp": require("../Assets/Ghosts/lineup/SCAREGROW.webp").default,
          "width": 383,
          "height": 291
      },
      "card": require("../Assets/Ghosts/Cards/SPECTRAL SCAREGROW.jpg").default,
      "infos": {
          "kawaii name": "Fearseed",
          "kaiju name": "Scaregrow",
          "level": "9",
          "family": "Spectral",
          "types": "Merger / Plant / Chaotic / Night",
          "nest": "A scarecrow",
          "receptacle": "None",
          "age": "Abomination",
          "rarity": "Very rare",
          "deplacement": "Levitating.",
          "Weakness": "SCAREGROW\u2019s impressive root network is key to both its offense and defense. Luckily, it is just as hard for the ghost to dodge and navigate it as it is for others\u2026 Allowing to turn its powers against SCAREGROW, by entangling it in its own roots!",
          "": "",
          "attacks": [
              {
                  "number": 1,
                  "name": "ROOT CHARGE",
                  "text": "In its Kawa\u00ef mode, SCAREGROW can go through people to trap them in the grasp of giant roots that\u2019ll grow under their feet\u2019s. It usually does so by diving into the ground and chasing after people like some sort of \u201cground shark\u201d. Spooky!"
              },
              {
                  "number": 2,
                  "name": "ROOT SEEDS",
                  "text": "In its Booster mode, SCAREGROW can shoot out spooky seeds from its hands. As they hit any solid structure, those seeds then turn into spectral roots that quickly grow and chase after people, capturing anyone they can find\u2026 And quickly turning the entire area into an hostile maze of thorny roots!"
              },
              {
                  "number": 3,
                  "name": "ROOT WHIP",
                  "text": "In its Booster mode, SCAREGROW can rapidly grow a thread of roots that he can then uses to throw at his opponents\u2026 Or just to whip them into submission! In any case : the thread is easily detachable from its hand and will wrap itself around its target, effectively neutralizing it."
              },
              {
                  "number": 4,
                  "name": "UNKNOWN",
                  "text": "(unused in episode)"
              }
          ],
          "defenses": [
              {
                  "number": 1,
                  "name": "SCAREGROWL (unused in episode)",
                  "text": "In booster mode, SCARGROW can let out a terrifying growl that is sure to temporarily stun anyone hearing it, as they shudder in fear for a beat."
              },
              {
                  "number": 2,
                  "name": "ROOT WALL (unused in episode)",
                  "text": "In its booster mode, SCAREGROW can very quickly shoot down a seed, that\u2019ll immediately grow into a wall of roots, to shield itself from incoming attacks."
              },
              {
                  "number": 3,
                  "name": "LEAFY COCOONS",
                  "text": "As it finally gets sated with boo energy, SCAREGROW can unleash it in the form of a giant seed that digs into the ground\u2026 And quickly grows into a giant spooky tree, with a multitude of leafy cocoons sprouting from its branches. Inside those cocoons, an army of newborn SCAREGROW start to grow\u2026 Menacing to unleash a new generation of ghost onto the world if the original one isn\u2019t stopped in time !"
              }
          ]
      }
  },
  {
      "name": "Scorpod",
      "image": {
          "png": require("../Assets/Ghosts/lineup/SCORPOD.png").default,
          "webp": require("../Assets/Ghosts/lineup/SCORPOD.webp").default,
          "width": 383,
          "height": 291
      },
      "card": require("../Assets/Ghosts/Cards/VISCOUS  SCORPOD.jpg").default,
      "infos": {
          "kawaii name": "Flamagma",
          "kaiju name": "Scorpod",
          "level": "5 then 6",
          "family": "Viscous",
          "types": "Rock / Plant",
          "nest": "An old amber ornament, then terrestrial globe",
          "receptacle": "None",
          "age": "Awe then Horror",
          "rarity": "Rare",
          "deplacement": "Levitation, while snapping its claws menacingly",
          "Weakness": "As a Rock and Plant type, SCORPOD Boo energy as turned into a rare form of Amber Boo, who\u2019s proving uniquely sensitive to vibrations. As such, any sound wave powerful enough could defeat this seemingly overpowered ghost\u2026 So, rock on!",
          "": "",
          "attacks": [
              {
                  "number": 1,
                  "name": "AMBER CHARGE (unused in episode)",
                  "text": "In its Kawa\u00ef mode, SCORPOD can encase anyone its passes through in a shell of indestructible solidified amber! Basically fossilizing its victims\u2026 Forever!"
              },
              {
                  "number": 2,
                  "name": "AMBER SHOT",
                  "text": "In its booster mode, SCORPOD ability evolve to allows it to shoot a barrage of Amber Boo projectile out of its two huge crab-like pinchers, that will also trap anything \u2013 and anyone \u2013 they hit in a shell of amber, fossilizing them."
              },
              {
                  "number": 3,
                  "name": "AMBERGEDDON (at level 6 only)",
                  "text": "In its booster mode, at level 6, SCORPOD\u2019s tail pincer becomes able to shoot a beam a pure Amber Boo, that\u2019ll not only act as a more potent version of its AMBER SHOT ability\u2026 But allows it to also affect Boo energy with its fossilizing powers!"
              }
          ],
          "defenses": [
              {
                  "number": 1,
                  "name": "PINCER DANCE",
                  "text": "In booster mode, SCORPOD grows out two huge crab-like claws. While these allows it to throw pinching attacks, their main effects are to provoke, terrorize and disturb SCORPOD\u2019s foes by snapping at them menacingly."
              },
              {
                  "number": 2,
                  "name": "PINCER WALL",
                  "text": "In its booster mode, SCORPOD can also use its two giant claws to shield itself from incoming attack. A tactic that can come in handy if it needs to defend itself in a pinch!"
              }
          ]
      }
  },
  {
      "name": "Scream Scratch",
      "image": {
          "png": require("../Assets/Ghosts/lineup/SCREAM SCRATCH.png").default,
          "webp": require("../Assets/Ghosts/lineup/SCREAM SCRATCH.webp").default,
          "width": 383,
          "height": 291
      },
      "card": require("../Assets/Ghosts/Cards/MOLECULAR SCREAM SCRATCH.jpg").default,
      "infos": {
          "kawaii name": "Gringag",
          "kaiju name": "Scream Scratch",
          "level": "4",
          "family": "Molecular",
          "types": "Prankster / Summoner",
          "nest": "A bag of doggy treats",
          "receptacle": "None",
          "age": "Dread",
          "rarity": "Rare",
          "deplacement": "Levitation.",
          "Weakness": "As SCREAM SCRATCH powers originate from its fur, coating it in any sort of Boo energy based overlay will render it completely and utterly harmless\u2026 Except maybe for its army of Furballs! So, stick away from those too!",
          "": "",
          "attacks": [
              {
                  "number": 1,
                  "name": "SCRATCHY CHARGE",
                  "text": "In its Kawa\u00ef mode, SCREAM SCRATCH fur will give anyone it passes through the itches, by making them feel like they\u2019re now hosting an entire army of fleas! Spooky\u2026 And scratchy!"
              },
              {
                  "number": 2,
                  "name": "SCRATCHY STORM",
                  "text": "In its Booster mode, SCREAM SCRATCH can send a storm of furry projectiles from its flea-infested fur, by spinning on itself like a big ball of\u2026 Well, flea-infested fur. Anyone hit by those projectile will then be turn into a FURBALL, joining in its ever-growing flea-infested army."
              }
          ],
          "defenses": [
              {
                  "number": 1,
                  "name": "FURBALLS ARMY",
                  "text": "In its Booster mode, SCREAM SCRATCH victims become FURBALLS, mindless pile of fur and fleas that spins around, spreading their master\u2019s curse to everyone else they get in contact with! Beware, as the ever-growing number of FURBALLS can quickly overwhelm anyone not prepare."
              }
          ]
      }
  },
  {
      "name": "Sandyrock",
      "image": {
          "png": require("../Assets/Ghosts/lineup/Sandyrock.png").default,
          "webp": require("../Assets/Ghosts/lineup/Sandyrock.webp").default,
          "width": 383,
          "height": 291
      },
      "card": require("../Assets/Ghosts/Cards/SPECTRAL SANDYROCK.jpg").default,
      "infos": {
          "kawaii name": "Torsand",
          "kaiju name": "Sandyrock",
          "level": "7",
          "family": "Spectral",
          "types": "Wind / Rock / Merger",
          "nest": "Old beach bucket",
          "receptacle": "Stone gargoyle",
          "age": "Panic",
          "rarity": "Uncommon",
          "deplacement": "Levitating and twirling around like a little sandstorm in Kawa\u00ef mode, flying like a mighty \u2013 if a bit heavy \u2013 gargoyle in Booster mode.",
          "Weakness": "As SANDYROK\u2019s power originates from its SAND SWIRL RESERVE ability, taking it out is a must. But beware, the ghost does try to hide its sand swirl inside its very resilient body at all time, only revealing it when its recharging its fists\u2026 So wait for the reload before striking!",
          "": "",
          "attacks": [
              {
                  "number": 1,
                  "name": "SAND TRAP CHARGE",
                  "text": "In its Kawa\u00ef mode, TORSAND can trap anyone it passes through under a big pile of spectral sand! Its victims become stuck and unable to dig their way out of the sand without outside help\u2026 And even then, digging them out might take a while!"
              },
              {
                  "number": 2,
                  "name": "SANDROCKET PUNCH",
                  "text": "In its Booster mode, SANDYROK can hurl away its fist like some sand-propelled rockets. As they reach their targeted destination, they then explode into a huge pile of spectral sand\u2026 Trapping anyone nearby under it! New fists then starts forming at the end of SANDYROK\u2019s arms, thanks to its SAND SWIRL RESERVE ability, allowing to shoot again a few seconds latter."
              },
              {
                  "number": 3,
                  "name": "A FISTFUL OF SAND",
                  "text": "In its Booster mode, SANDYROK\u2019s massive fists can act not only as projectiles\u2026 But also just like massive fists! Take care not to get hit by one of its punches, as this ghost does pack quite a\u2026 Uh, well, punch. And those are sure to knock you away!"
              }
          ],
          "defenses": [
              {
                  "number": 1,
                  "name": "ROCKY BODY",
                  "text": "In both its Booster mode, SANDYROK sports a massive gargoyle\u2019s rocky body that proves extremely resilient to most attack."
              },
              {
                  "number": 2,
                  "name": "SAND SWIRL RESERVE",
                  "text": "In its Booster mode, SANDYROCK shelters a Sand Swirl inside its body that it uses to generates more spectral sand, which allows it to regenerate its fists. While this ability doesn\u2019t do much on its own, its at the hearth of SANDYROCK\u2019s SANDROCKET PUNCH ability, as it allows it to use it again and again, without fear of running out of ammo\u2026 Or, in that case, fists!"
              }
          ]
      }
  },
  {
      "name": "Somnibou",
      "image": {
          "png": require("../Assets/Ghosts/lineup/Somnibou.png").default,
          "webp": require("../Assets/Ghosts/lineup/Somnibou.webp").default,
          "width": 383,
          "height": 291
      },
      "card": require("../Assets/Ghosts/Cards/SPECTRAL SOMNIBOU.jpg").default,
      "infos": {
          "kawaii name": "Breathrill",
          "kaiju name": "Somnibou",
          "level": "7",
          "family": "Spectral",
          "types": "Wind / Dream / Night",
          "nest": "Old mattress",
          "receptacle": "None",
          "age": "Panic",
          "rarity": "Rare",
          "deplacement": "Flying like an owl.",
          "Weakness": "SOMNIBOO\u2019s tail feathers are sheltering its energy source; a ball of pure Boo energy from which emanates its sleeping power. Make sure to reach it and you\u2019ll defeat that ghost in no time\u2026 The only issue being that SOMNIBOO tends to keep it covered behind its feathers for protection! So you might have to find a way to convince him to relax its guard beforehand\u2026",
          "": "",
          "attacks": [
              {
                  "number": 1,
                  "name": "FLAGADOO",
                  "text": "In its Kawaii mode, SOMNIBOO can puts anyone it passes though in a state of complete torpor. It\u2019s victims\u2019 bodies softens and their eyes fog up... Until they finally fall fully asleep after a few seconds. Sleep well!"
              },
              {
                  "number": 2,
                  "name": "SANDMAN\u2019S FLYBY",
                  "text": "In its Booster mode, SOMNIBOU can shed spectral dust through its feather-covered tail. As it fly over people, the dust covers them and they plunges into an immediate and eternal sleep. Sweet dreams!"
              },
              {
                  "number": 3,
                  "name": "FEATHER BLAST",
                  "text": "In its Booster mode, SOMNIBOO can throw feather-like projectile with a sharp, brisk stroke of its wings. Whenever one of those feathers hits its target, it disintegrates into a cloud of spectral dust and puts the person who gets sprayed with it to sleep. NIght-night!"
              }
          ],
          "defenses": [
              {
                  "number": 1,
                  "name": "BOO ENER-SLEEP",
                  "text": "Where most Ghosts have to feed on fear to power up their attacks, SOMNIBOO is also able to convert the dreams of sleeping people into Boo energy. This allows it to power itself up\u2026 Just by watching you sleep! Creepy."
              },
              {
                  "number": 2,
                  "name": "NIGHT OWL AURA",
                  "text": "In its Booster mode, SOMNIBOO passively generates a nightly aura, that makes night instantly falls around it! This aura follows it around and assures it to always be acting at night\u2026 Even in when it\u2019s supposed to be bright noon!"
              }
          ]
      }
  },
  {
      "name": "Troublestretch",
      "image": {
          "png": require("../Assets/Ghosts/lineup/TROUBLESTRETCH.png").default,
          "webp": require("../Assets/Ghosts/lineup/TROUBLESTRETCH.webp").default,
          "width": 383,
          "height": 291
      },
      "card": require("../Assets/Ghosts/Cards/SPECTRAL TROUBLESTRETCH.jpg").default,
      "infos": {
          "kawaii name": "Stretchee",
          "kaiju name": "Troublestretch",
          "level": "7",
          "family": "Flexible",
          "types": "Prankster / Chaotic / Merger",
          "nest": "Old rope",
          "receptacle": "Woolen key ring",
          "age": "Panic",
          "rarity": "Rare",
          "deplacement": "Levitation.",
          "Weakness": "TROUBLESTRETCH\u2019s HEAD BELL always comes back to its body after it shot it out. Meaning that simply coating it with some sort of Boo energy will ensure delivery of the attack\u2026 Straight inside to the ghost main body!",
          "": "",
          "attacks": [
              {
                  "number": 1,
                  "name": "BOOLAS SHOT",
                  "text": "In its Kawa\u00ef mode, STRETCHEE can throw out small shots of flexy that expands as they hit someone, completely entangling them, like some sort of bolas!"
              },
              {
                  "number": 2,
                  "name": "WOOLY PUNCH",
                  "text": "In its Booster mode, TROUBLESTRETCH is able to turn people into sleigh bells with a simple swing of its wooly arms. And since its arms can extend greatly, it\u2019s able to touch a LOT of things and people in a single swing!"
              },
              {
                  "number": 3,
                  "name": "HEAD BELL",
                  "text": "In its booster mode, TROUBLESTRECHT can expulse the tiny sleigh bell on its head and throw it at its foes, while keeping it connected to its body by a long thread of wool. This bell then act as teleguided missile, chasing after its target at great speed and knitting impressive figures with the yarn its pulls behind! But more importantly: It\u2019ll turn anyone it hit into yet another sleigh bell, before getting pulled back to TROUBLESTRECHT body!"
              }
          ],
          "defenses": [
              {
                  "number": 1,
                  "name": "WOOLY BODY",
                  "text": "In its Booster mode, TROUBLESTRECH\u2019s wooly body can easily, squeeze, stretch or generally distort itself in a great variety of way, in order to help it dodge incoming attack."
              },
              {
                  "number": 2,
                  "name": "BUTTON SHIELDS",
                  "text": "In its booster mode, TROUBLESTRETCH\u2019s hands ends with two very resilient buttons that it uses to deflect incoming attacks."
              }
          ]
      }
  },
  {
      "name": "Turbokorn",
      "image": {
          "png": require("../Assets/Ghosts/lineup/TURBOKORN.png").default,
          "webp": require("../Assets/Ghosts/lineup/TURBOKORN.webp").default,
          "width": 383,
          "height": 291
      },
      "card": require("../Assets/Ghosts/Cards/FRACTAL TURBOKORN.jpg").default,
      "infos": {
          "kawaii name": "Metabull",
          "kaiju name": "Turbokorn",
          "level": "4",
          "family": "Fractal",
          "types": "Metal / Merger",
          "nest": "An old bell with a bullhead carved on",
          "receptacle": "A racecar",
          "age": "Dread",
          "rarity": "Rare",
          "deplacement": "Levitating in Kawa\u00ef mode\u2026 And though it can do the same in Booster mode, it usually prefers driving, drifting and racing around like a racecar!",
          "Weakness": "TURBOKORN is so fast, you\u2019ll never catch it without a proper mean of transportation. So get a speedy mount, a speedier vehicle or the speediest Ghost Car, buckle up\u2026 And get ready to race!",
          "": "",
          "attacks": [
              {
                  "number": 1,
                  "name": "METAL CHARGE",
                  "text": "In both its Kawa\u00ef and Booster mode, METABULL / TURBOKORN can turn anyone it passes through into metal statues. And it\u2019s obviously even more dangerous while boosted and merged with a racecar! Beware its drifting and racing skills."
              },
              {
                  "number": 2,
                  "name": "EXHAUST BLAST",
                  "text": "In its booster mode, TURBOKORN can shoot out cannonball from its multiples exhaust pipes. Those projectiles have the same properties as its METAL CHARGE ability and will turn anyone hit into a metal statue. TURBOKORN mostly uses this ability to shake anyone trying to keep up with its speed as it races away."
              }
          ],
          "defenses": [
              {
                  "number": 1,
                  "name": "BULL RUSH HOUR",
                  "text": "In its Kawa\u00ef mode, METABULL is already a fast little spook\u2026 But as it merges with a racecar and becomes TURBOKORN, it becomes one of the fastest ghost ever! Its speed is unmatched and its practically impossible to keep up with it, as it races to sow fear and chaos at the speed of light."
              }
          ]
      }
  },
  ...fakeGhosts,
];